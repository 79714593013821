import React, { useContext } from "react"

// User Ctx
import { UserContext } from "../../context/userContext"

// Worbkench per Role
import RoleAnalyst from "./RoleAnalyst"
import RoleAdmin from "./RoleAdmin"

export default function Home() {
  const { user_role } = useContext(UserContext)
  switch (user_role) {
    case 10:
    case 11:
    case 15:
      return <RoleAnalyst />
    case 0:
    case 1:
      return <RoleAdmin />
    default:
      return null
  }
}
