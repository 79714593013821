import React from "react"
import { Link } from "react-router-dom"
import styles from "../../styles/NavMain.module.css"

export default function MenuAdmin() {
  return (
    <div className={styles.mainMenu}>
      <ul>
        <li>
          <Link to="/">Workbench</Link>
        </li>
        <li>
          <Link to="/user/list">User Directory</Link>
        </li>
        <li>
          <Link to="/carrier-directory/list">Carrier Directory</Link>
        </li>
        <li>
          <Link to="/hospital-directory/list">Hospital Directory</Link>
        </li>
        <li>
          <Link to="/index-file/list">Index Management</Link>
        </li>
        <li>
          <Link to="/data-order/list">Project Data Orders</Link>
        </li>
        <li>
          <Link to="/organization/list">Manage Organizations</Link>
        </li>
        <li>
          <Link to="/data-room/">Data Room</Link>
        </li>
      </ul>
    </div>
  )
}
