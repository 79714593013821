import React, { useRef, useEffect, useState } from "react"
import axios from "axios"
import _ from "lodash"

import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"
import { db } from "../../firebase"

import cloudFunctionsURL from "../../config/cloudFunctionsURL"
import { GET } from "../../config/constants"

// helpers
import trimURL from "../../helpers/trimURL"

// hooks
import useSubscribeAllDocs from "../../hooks/useSubscribeAllDocs"

// PR
import { Button } from "primereact/button"
import { SplitButton } from "primereact/splitbutton"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Toast } from "primereact/toast"

export default function CarrierDirectoryList() {
  const [fetching, setFetching] = useState(true)
  const [coverageAreasOptions, setCoverageAreasOptions] = useState([])

  const hookParams = {
    collection: "carrier_directory",
  }

  useEffect(() => {
    const fetchData = async () => {
      const url = `${cloudFunctionsURL()}/${GET.PRODUCT_COVERAGE_AREA}`
      const response = await axios.post(url, {
        geo: "msas",
      })
      setCoverageAreasOptions(response.data.data)
      setFetching(false)
    }
    fetchData()
  }, [])

  const [loading, error, data] = useSubscribeAllDocs(hookParams)

  const navigate = useNavigate()
  const toast = useRef(null)

  if (error) {
    return (
      <div>
        <h2>Error when fetching.</h2>
      </div>
    )
  }

  const handleDelete = async (docId) => {
    try {
      await db
        .collection("carrier_directory")
        .doc(docId)
        .delete()
      toast.current.show({
        severity: "success",
        summary: "Document Deleted",
        detail: "The document was successfully deleted.",
        life: 3000,
      })
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error Deleting Document",
        detail: "An error occurred while deleting the document.",
        life: 3000,
      })
    }
  }

  const handleActive = async (docId) => {
    try {
      await db
        .collection("carrier_directory")
        .doc(docId)
        .update({
          carrier_status: "ACTIVE",
        })
      toast.current.show({
        severity: "success",
        summary: "Carrier Activated",
        detail: "The Carrier was successfully activated.",
        life: 3000,
      })
    } catch (error) {
      console.error("Error archiving document: ", error)
    }
  }

  const handleInactive = async (docId) => {
    try {
      await db
        .collection("carrier_directory")
        .doc(docId)
        .update({
          carrier_status: "INACTIVE",
        })
      toast.current.show({
        severity: "success",
        summary: "Carrier Inactive",
        detail: "The Carrier was successfully inactived.",
        life: 3000,
      })
    } catch (error) {
      console.error("Error archiving document: ", error)
    }
  }

  const orderedData = _.orderBy(data, ["reporting_entity_name"])

  const handleData = _.map(orderedData, (row) => {
    const handleStatus = () => {
      const { carrier_status: status } = row
      switch (status) {
        case "ACTIVE":
          return (
            <div style={{ color: "var(--green-600)" }}>
              <i className="pi pi-circle-fill" style={{ marginRight: 10 }} />
              Active
            </div>
          )
        case "INACTIVE":
          return (
            <div style={{ color: "var(--red-400)" }}>
              <i className="pi pi-circle" style={{ marginRight: 10 }} />
              Inactive
            </div>
          )
      }
    }
    const handleActions = (row) => {
      const { id: docId, carrier_status: status } = row
      const handleItems = () => {
        switch (status) {
          case "ACTIVE":
            return [
              {
                label: "Inactivate",
                icon: "pi pi-briefcase",
                command: () => handleInactive(docId),
              },
            ]
          case "INACTIVE":
            return [
              {
                label: "Activate",
                icon: "pi pi-check",
                command: () => handleActive(docId),
              },
            ]
        }
      }
      const items = handleItems()
      items.push({
        label: "Delete",
        icon: "pi pi-times",
        command: () => handleDelete(docId),
      })
      return (
        <SplitButton
          label="Edit"
          onClick={() => navigate(`/carrier-directory/edit/${docId}`)}
          model={items}
        />
      )
    }

    const modDate = row.timestamp_updated
      ? row.timestamp_updated.toDate()
      : null

    const handleCoverageAreas = () => {
      if (_.isEmpty(coverageAreasOptions)) return ""
      const { product_coverage_area_msas } = row
      const msasArray = _.map(product_coverage_area_msas, (areaValue) => {
        const msaObject = _.find(coverageAreasOptions, { value: areaValue })
        return msaObject.label || ""
      })
      return msasArray.join(" | ")
    }

    const handleProductCoverage = () => {
      if (_.isEmpty(row.product_coverage_area_states)) return ""
      return row.product_coverage_area_states.join(" | ")
    }

    return {
      ...row,
      carrier_status: handleStatus(),
      product_coverage_area_states: handleProductCoverage(),
      product_coverage_area_msas: handleCoverageAreas(),
      mrf_source_location: !_.isEmpty(row.mrf_source_location) ? (
        <a href={row.mrf_source_location} target="_black">
          <i className="pi pi-external-link" style={{ marginRight: 5 }} />
          {trimURL(row.mrf_source_location)}...
        </a>
      ) : null,
      updated: DateTime.fromJSDate(modDate).toFormat("F"),
      actions: handleActions(row),
    }
  })

  return (
    <div className="container">
      <h1>Carrier Directory</h1>
      <Button
        onClick={() => navigate("/carrier-directory/add")}
        style={{ marginBottom: 15 }}
      >
        Add New Carrier
      </Button>
      <DataTable
        value={handleData}
        responsiveLayout="scroll"
        loading={fetching || loading}
      >
        <Column
          field="reporting_entity_name"
          header="Carrier Name"
          filter
          sortable
        />
        <Column
          field="reporting_market_name"
          header="Insurance Market Name"
          sortable
        />

        <Column field="product_coverage_area_states" header="State" sortable />

        <Column field="product_coverage_area_msas" header="Coverage Area" />

        <Column
          field="mrf_source_location"
          header="TIC URL"
          style={{ width: 420 }}
        />
        <Column
          field="carrier_status"
          header="Carrier Status"
          style={{ width: 200 }}
        />
        <Column field="updated" header="Last Update" />
        <Column field="actions" header="Actions" />
      </DataTable>
      <Toast ref={toast} />
    </div>
  )
}
