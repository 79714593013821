import React, { useState } from "react"
import _ from "lodash"

// HC Dashboard
import Dashboard from "../../components/Dashboard"

// PR
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"

// Helpers
import dataGridColFormatter from "../../../../../helpers/dataGridColFormatter"

export default function Bar({ payload }) {
  const [currentData, setCurrentData] = useState(payload.data)

  if (_.isEmpty(payload)) {
    return <div>No results found.</div>
  }

  let configDynamic = payload.dashboard

  const addRow = (newRowName) => {
    const initialValue = 0
    const numberOfColumns = payload.data[0].length - 1
    const defaultColValue = _.fill(Array(numberOfColumns), initialValue)
    const newRow = [newRowName, ...defaultColValue]
    const newData = (payload.data = [...payload.data, newRow])
    setCurrentData(newData)
  }

  function HandleDashboard() {
    const setConnector = (connectorId, data) => {
      const connector = _.find(configDynamic.dataPool.connectors, {
        id: connectorId,
      })
      _.set(connector, "options.data", data)
    }

    const setCategories = () => {
      const component = _.find(configDynamic.components, {
        cell: "dashboard-col-1",
      })
      component.chartOptions.xAxis.categories = currentData
        .map((row) => row[0])
        .slice(1)
    }

    // Set data to connector
    setConnector("connector-1", currentData)

    setCategories()

    return <Dashboard config={configDynamic} />
  }

  function HandleManualInput() {
    const [rowName, setRowName] = useState("")
    const isDisabledBtn = _.isEmpty(rowName)
    return (
      <div
        className="p-inputgroup flex-1"
        style={{ width: "fit-content", margin: 15 }}
      >
        <InputText
          placeholder="Custom Row Name"
          value={rowName}
          onChange={(e) => setRowName(e.target.value)}
        />
        <Button
          label="Add Row"
          onClick={() => {
            addRow(rowName)
            setRowName("")
          }}
          disabled={isDisabledBtn}
        />
      </div>
    )
  }

  return (
    <div
      style={{
        borderTop: "1px solid lightgrey",
        paddingTop: 15,
        marginTop: 15,
      }}
    >
      <HandleDashboard />
      {/* <HandleManualInput /> */}
    </div>
  )
}
