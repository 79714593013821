import React from "react"

import { InputText } from "primereact/inputtext"
import { Field, useFormikContext } from "formik"

import styles from "../../../styles/Form.module.css"

export default function ProjectBi(props) {
  const form = useFormikContext()
  const { fieldname, label } = props
  const handleInputChange = (e) => {
    const inputValue = e.target.value
    const filteredValue = inputValue.replace(/[^\w\s]/gi, "")
    const transformedValue = filteredValue.replace(/\s+/g, "_")
    return form.setFieldValue(fieldname, transformedValue)
  }
  return (
    <Field name={fieldname}>
      {({ field, meta }) => {
        return (
          <div className={styles.field}>
            <label htmlFor={fieldname} className={styles.label}>
              {label}
            </label>
            <InputText
              id={fieldname}
              value={field.value}
              onChange={(e) => handleInputChange(e)}
              onBlur={field.onBlur}
              readOnly={props.editMode}
            />
            {meta.touched && meta.error && (
              <div className={styles.error}>{meta.error}</div>
            )}
          </div>
        )
      }}
    </Field>
  )
}
