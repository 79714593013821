import React, { useContext } from "react"
import { Route, Routes, Navigate } from "react-router-dom"
import _ from "lodash"

import { routesDef } from "./routesDef"
import { UserContext } from "../../context/userContext"

function RequireAdmin(props) {
  const user = useContext(UserContext)
  const leastPrivilege = Number(props.leastPrivilege)
  const isAllow = user.user_role <= leastPrivilege
  if (!isAllow) return <Navigate to="/denied" replace />
  return props.children
}

export default function RoutesBuilder() {
  const getRouteElements = (routesDef) => {
    return _.map(routesDef, (route, i) => {
      const { requireAdmin, leastPrivilege } = route
      const element = requireAdmin ? (
        <RequireAdmin key={route.path} leastPrivilege={leastPrivilege}>
          {route.element}
        </RequireAdmin>
      ) : (
        route.element
      )
      return <Route key={i} path={route.path} element={element} />
    })
  }

  function handleRoutes() {
    const routeList = getRouteElements(routesDef)
    return <Routes>{routeList}</Routes>
  }

  return <>{handleRoutes()}</>
}
