import React from "react"
import { SelectButton } from "primereact/selectbutton"

export default function MultipleDemo(props) {
  const items = [
    { name: "Market Rate Analysis", value: 1 },
    { name: "Regional Comparison", value: 2 },
    { name: "Price/Quality Matrix", value: 3 },
  ]

  return (
    <div className="card flex">
      <SelectButton
        value={props.insight}
        onChange={(e) => props.setInsights(e.value)}
        optionLabel="name"
        options={items}
      />
    </div>
  )
}
