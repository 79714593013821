import React, { useState } from "react"
import axios from "axios"

import { Formik, Form } from "formik"
import { useNavigate } from "react-router-dom"

import schemaBuilder from "../../components/schemaBuilder"

// constants
import { ENTITY, DEF, ADD, EDIT } from "../../config/constants"

// hooks
import useGetFormDef from "../../hooks/useGetFormDef"

// functions URL
import cloudFunctionsURL from "../../config/cloudFunctionsURL"

// comps
import FieldBuilder from "../../components/FieldBuilder"
import { LoaderSkeleton } from "../../components/Loaders"

// PR
import { Button } from "primereact/button"

export default function FormWrapper({ type, docValues }) {
  const navigate = useNavigate()

  const [msg, setMsg] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const params = {
    formId: ENTITY.ORGANIZATION,
    endpoint: DEF.FORM,
  }

  const [error, loading, def] = useGetFormDef(params)

  if (error) return <div>Err...</div>
  if (loading) return <LoaderSkeleton count={4} />

  const [initialValues, validationSchema] = schemaBuilder(def.fields)

  const handleSubmit = async (values) => {
    setSubmitting(true)
    const endpoint = () => {
      switch (type) {
        case "add":
          return ADD.ORGANIZATION
        case "edit":
          return EDIT.ORGANIZATION
      }
    }
    const url = `${cloudFunctionsURL()}/${endpoint()}`
    try {
      const response = await axios.post(url, values)
      setSubmitting(false)
      if (response.data.status === "success") {
        return navigate("/organization/list")
      }
      setMsg(response.data.msg)
    } catch (error) {
      const { msg } = error.response.data.data
      setMsg(msg)
      setSubmitting(false)
    }
  }

  const handleInitialValues = () => {
    switch (type) {
      case "add":
        return initialValues
      case "edit":
        return {
          ...initialValues,
          ...docValues,
        }
    }
  }

  if (msg) {
    return (
      <div>
        <h3>Error creating User</h3>
        <p>{msg}</p>
        <Button
          icon="pi pi-backward"
          label="Back"
          size="small"
          onClick={() => setMsg(null)}
        />
      </div>
    )
  }

  return (
    <Formik
      initialValues={handleInitialValues()}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form style={{ margin: "15px 30px" }}>
          <FieldBuilder fields={def.fields} editMode={type === "edit"} />
          <Button type="submit" disabled={submitting}>
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  )
}
