import React, { useRef, useState } from "react"
import axios from "axios"
import _ from "lodash"
import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"

import { Button } from "primereact/button"
import { SplitButton } from "primereact/splitbutton"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Toast } from "primereact/toast"

import cloudFunctionsURL from "../../config/cloudFunctionsURL"

// constants
import { DELETE } from "../../config/constants"

import useSubscribeAllDocs from "../../hooks/useSubscribeAllDocs"
import handleStatusMessage from "../../helpers/handleStatusMessage"

export default function IndexFileList() {
  const [deletingMRFOrder, setDeletingMRFOrder] = useState(false)

  const hookParams = {
    collection: "mrf_orders",
    withRef: {
      key: "ref_carrier_directory",
      value: ["reporting_entity_name"],
    },
    filter: ["mrf_type", "==", "index"],
  }

  const [loading, error, data] = useSubscribeAllDocs(hookParams)

  const navigate = useNavigate()
  const toast = useRef(null)

  const handleDelete = async (docId) => {
    try {
      setDeletingMRFOrder(true)
      const url = `${cloudFunctionsURL()}/${DELETE.MRF_ORDER}`
      const response = await axios.post(url, {
        mrf_order_id: docId,
      })
      setDeletingMRFOrder(false)
      if (response.data.status === "success") {
        return toast.current.show({
          severity: "success",
          summary: "Data Order Deleted",
          detail: response.data.message,
          life: 3000,
        })
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error while trying to delete Data Order",
          detail: "The document was not successfully deleted.",
          life: 3000,
        })
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error Deleting Document",
        detail: "An error occurred while deleting the document.",
        life: 3000,
      })
    }
  }

  const handleData = _.map(data, (row) => {
    const handleActions = (docId) => {
      const items = [
        {
          label: "Delete",
          icon: "pi pi-times",
          command: () => handleDelete(docId),
        },
      ]
      return (
        <SplitButton
          label="Edit"
          onClick={() => navigate(`/index-file/edit/${docId}`)}
          model={items}
          disabled={deletingMRFOrder}
        />
      )
    }
    const modDate = row.timestamp_updated
      ? row.timestamp_updated.toDate()
      : null
    return {
      ...row,
      ref_carrier_directory: row.ref_carrier_directory.reporting_entity_name,
      status: handleStatusMessage(row.mrf_order_status),
      updated: DateTime.fromJSDate(modDate).toFormat("F"),
      actions: handleActions(row.id),
    }
  })

  if (error) {
    return (
      <div>
        <h2>Error when fetching.</h2>
      </div>
    )
  }

  return (
    <div className="container">
      <h1>Index Management</h1>
      <Button
        onClick={() => navigate("/index-file/add")}
        style={{ marginBottom: 15 }}
      >
        Add Index File
      </Button>
      <DataTable value={handleData} responsiveLayout="scroll" loading={loading}>
        <Column
          field="ref_carrier_directory"
          header="Carrier Name"
          filter
          sortable
        />
        <Column
          field="mrf_order_name"
          header="Commercial Insurance Plan Index"
          filter
          sortable
        />
        <Column field="mrf_order_alias" header="Index Data Table" />
        <Column field="status" header="Status" />
        <Column field="updated" header="Last Update" sortable />
        <Column field="actions" header="Actions" />
      </DataTable>
      <Toast ref={toast} />
    </div>
  )
}
