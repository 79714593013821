import React, { useState } from "react"

import SelectionBtns from "../components/SelectionBtns"

import { Dropdown } from "primereact/dropdown"
import { Fieldset } from "primereact/fieldset"

export default function FiltersSection(props) {
  const [selectedCarriers, setSelectedCarriers] = useState(null)
  const [selectedProviders, setSelectedProviders] = useState(null)

  const carriers = [
    { name: "Aetna", code: "Aetna" },
    { name: "BCBS", code: "BCBS" },
    { name: "Cigna", code: "Cigna" },
    { name: "UHC", code: "UHC" },
  ]
  const providers = [
    { name: "Provider 1", code: "Provider1" },
    { name: "Provider 2", code: "Provider2" },
    { name: "Provider 3", code: "Provider3" },
    { name: "Provider 4", code: "Provider4" },
  ]

  return (
    <Fieldset legend="Filters" toggleable>
      <div className="flex gap-3 mb-2">
        <div className="filter">
          <label>Select Carrier</label>
          <Dropdown
            value={selectedCarriers}
            onChange={(e) => setSelectedCarriers(e.value)}
            options={carriers}
            optionLabel="name"
            placeholder="Carrier"
            className="w-full md:w-14rem"
          />
        </div>
        <div className="filter">
          <label>Select Provider</label>
          <Dropdown
            value={selectedProviders}
            onChange={(e) => setSelectedProviders(e.value)}
            options={providers}
            optionLabel="name"
            placeholder="Provider"
            className="w-full md:w-14rem"
          />
        </div>
      </div>
      <SelectionBtns insight={props.insight} setInsights={props.setInsights} />
    </Fieldset>
  )
}
