import React, { useEffect, useState } from "react"
import axios from "axios"
import { InputText } from "primereact/inputtext"
import { Field, useFormikContext } from "formik"

import cloudFunctionsURL from "../../../config/cloudFunctionsURL"

import { GET } from "../../../config/constants"

import styles from "../../../styles/Form.module.css"

export default function MrfOrderName(props) {
  const [fetching, setFetching] = useState(true)

  const { fieldname, label, description, dataOrderId } = props

  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    const fetchData = async () => {
      const url = `${cloudFunctionsURL()}/${GET.GET_DATA_ORDER_NAME}`
      const response = await axios.post(url, {
        data_order_id: dataOrderId,
      })
      if (response.data.status === "success") {
        const data_order_name = response.data.data.data_order_name || ""
        setFieldValue(fieldname, data_order_name)
        setFetching(false)
      }
    }
    fetchData()
  }, [dataOrderId])

  return (
    <Field name={fieldname}>
      {({ field, meta }) => {
        return (
          <div className={styles.field}>
            <label htmlFor={fieldname} className={styles.label}>
              {label}
            </label>
            {description && (
              <small style={{ display: "block", marginBottom: 10 }}>
                {description}
              </small>
            )}
            <InputText
              id={fieldname}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
            />
            {meta.touched && meta.error && (
              <div className={styles.error}>{meta.error}</div>
            )}
          </div>
        )
      }}
    </Field>
  )
}
