import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import _ from "lodash"
import { DateTime } from "luxon"
import axios from "axios"

import { Button } from "primereact/button"
import { SplitButton } from "primereact/splitbutton"

import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

import { GET } from "../../../config/constants"
import cloudFunctionsURL from "../../../config/cloudFunctionsURL"

import useSubscribeAllDocs from "../../../hooks/useSubscribeAllDocs"
import handleStatusMessage from "../../../helpers/handleStatusMessage"

export default function ProjectsAndDataOrders() {
  const [fetching, setFetching] = useState(true)
  const [projectMetadata, setProjectMetadata] = useState([])

  const hookParams = {
    collection: "mrf_orders",
    filter: ["mrf_type", "==", "in_network"],
    limit: 8,
  }

  const [loading, error, data] = useSubscribeAllDocs(hookParams)

  const navigate = useNavigate()

  useEffect(() => {
    if (!data) return
    const getProjectMetadata = async () => {
      const projectMetadataList = await Promise.all(
        _.map(data, async ({ id: mrf_order_id }) => {
          const metadataUrl = `${cloudFunctionsURL()}/${
            GET.PROJECT_METADATA_FROM_MRF_ORDER
          }`
          try {
            const res = await axios.post(metadataUrl, {
              mrf_order_id,
            })
            const fetchData = res.data.data
            fetchData.mrf_order_id = mrf_order_id
            return fetchData
          } catch (error) {
            return null
          }
        })
      )
      setProjectMetadata(projectMetadataList)
      setFetching(false)
    }
    getProjectMetadata()
  }, [data])

  const handleData = _.map(data, (row) => {
    const currentProjectMetadata =
      _.find(projectMetadata, {
        mrf_order_id: row.id,
      }) || {}

    const handleActions = () => {
      const {
        data_order_id: docId,
        data_order_status: status,
      } = currentProjectMetadata
      const items = [
        {
          label: "Find MRF to process",
          icon: "pi pi-search",
          command: () => navigate(`/data-order/edit/${docId}`),
        },
        {
          label: "Add MRF to process",
          icon: "pi pi-plus",
          command: () => navigate(`/data-order/edit/${docId}?screen=3`),
        },
      ]
      return (
        <SplitButton
          label="View Order"
          onClick={() => navigate(`/data-order/details/${docId}/${status}`)}
          model={items}
        />
      )
    }

    const handleMrfOrderName = (name) => {
      if (!name) return
      const maxLength = 24
      if (name.length > maxLength) {
        return name.substring(0, maxLength) + "..."
      }
      return name
    }

    const modDate = row.timestamp_updated
      ? row.timestamp_updated.toDate()
      : null

    return {
      ...row,
      mrf_order_name: handleMrfOrderName(row.mrf_order_name),
      mrf_order_stage: handleStatusMessage(row.mrf_order_stage),
      mrf_order_status: handleStatusMessage(row.mrf_order_status),
      project_name: (
        <Link to={`/project/details/${currentProjectMetadata.project_alias}`}>
          {currentProjectMetadata.project_name}
        </Link>
      ),
      updated: DateTime.fromJSDate(modDate).toFormat("F"),
      actions: handleActions(),
    }
  })

  return (
    <div>
      <h2 style={{ marginBottom: 5 }}>Projects and Data Orders</h2>
      <small style={{ display: "block" }}>Showing last eight entries.</small>
      <div style={{ margin: "8px 0 16px 0" }}>
        <Button
          onClick={() => navigate("/data-order/add")}
          style={{ marginRight: 15 }}
          className="btn-secondary"
        >
          Create New Data Order
        </Button>
        <Button onClick={() => navigate("/data-order/list")}>
          Manage Projects
        </Button>
      </div>

      <DataTable
        value={handleData}
        responsiveLayout="scroll"
        loading={loading || fetching}
      >
        <Column field="project_name" header="Project Name" />
        <Column field="mrf_order_name" header="Data Order Name" />
        <Column field="mrf_order_stage" header="Stage" />
        <Column field="mrf_order_status" header="Status" />
        <Column field="updated" header="Last Order Date" sortable />
        <Column field="actions" header="Actions" style={{ width: 240 }} />
      </DataTable>
      <br />
    </div>
  )
}
