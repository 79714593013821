import React, { useState } from "react"
import _ from "lodash"

// PR
import { SelectButton } from "primereact/selectbutton"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { MultiSelect } from "primereact/multiselect"

// HC Dashboard
import Dashboard from "../../components/Dashboard"

// Helpers
import dataGridColFormatter from "../../../../../helpers/dataGridColFormatter"

const FILTER_OPTIONS = [
  { label: "Avg Rates", value: "avg_rates" },
  { label: "Min Rates", value: "min_rates" },
  { label: "Max Rates", value: "max_rates" },
  // ***temp disabled for MMA***
  // {
  //   label: "Number Rates",
  //   value: "number_provider_rates",
  // },
]

export default function GroupedBar({ payload }) {
  const [chartSubtitle, setChartSubtitle] = useState("avg_rates")
  const [currentData, setCurrentData] = useState(payload.data.avg_rates)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  if (_.isEmpty(payload)) {
    return <div>No results found.</div>
  }

  let configDynamic = payload.dashboard

  function HandleDashboard() {
    const setConnector = (connectorId, data) => {
      const connector = _.find(configDynamic.dataPool.connectors, {
        id: connectorId,
      })
      _.set(connector, "options.data", data)
    }

    const setSubtitle = (cellId, subtitle) => {
      const cell = _.find(configDynamic.components, { cell: cellId })
      _.set(cell, "chartOptions.subtitle.text", subtitle)
    }

    const setLineAvarage = (cellId, series) => {
      const component = _.find(configDynamic.components, { cell: cellId })
      const { chartOptions } = component
      const averageSeries = _.find(chartOptions.series, { name: "Average" })
      _.set(averageSeries, "data", series)
    }

    const setSummaryRow = (cellId, summaryRow) => {
      const component = _.find(configDynamic.components, {
        cell: cellId,
        type: "DataGrid",
      })
      component.dataGridOptions = {
        ...component.dataGridOptions,
        footerFormat: summaryRow,
      }
    }

    const setGroupedBarCategories = () => {
      const component = _.find(configDynamic.components, {
        cell: "dashboard-col-1",
      })
      component.chartOptions.xAxis.categories = currentData
        .map((row) => row[0])
        .slice(1)
    }

    // Set data to connector
    setConnector("connector-1", currentData)

    // Set subtitle
    const currentSubtitle = _.find(FILTER_OPTIONS, {
      value: chartSubtitle,
    }).label
    setSubtitle("dashboard-col-1", currentSubtitle)

    // Set summary row
    setSummaryRow("dashboard-col-2", "<b>Total:</b> {point.total}")

    // Set categories
    setGroupedBarCategories()

    // Function to calculate the average of a numeric array
    const calculateAverage = (arr) =>
      arr.slice(1).reduce((sum, val) => sum + val, 0) / (arr.length - 1)

    // Create a new array 'medicareRate' with the averages
    const medicareRate = payload.data.medicare_rate
      .slice(1)
      .map((row) => calculateAverage(row))

    setLineAvarage("dashboard-col-1", medicareRate)

    // Format columns for integer values
    const formatterParams = {
      components: configDynamic.components,
      dashboardId: null,
      column: null,
      prefix: null,
    }

    dataGridColFormatter([
      {
        ...formatterParams,
        dashboardId: "dashboard-col-2",
        column: "Min Rate",
        prefix: "$",
      },
      {
        ...formatterParams,
        dashboardId: "dashboard-col-2",
        column: "Max Rate",
        prefix: "$",
      },
      {
        ...formatterParams,
        dashboardId: "dashboard-col-2",
        column: "AVG Rate",
        prefix: "$",
      },
    ])

    return <Dashboard config={configDynamic} />
  }

  const handleSetData = (newSubtitle, newData) => {
    setCurrentData(newData)
    setChartSubtitle(newSubtitle)
  }

  return (
    <div style={{ margin: 15 }}>
      <div style={{ display: "flex" }}>
        <SelectButton
          value={chartSubtitle}
          options={FILTER_OPTIONS}
          onChange={(e) => handleSetData(e.value, payload.data[e.value])}
          multiple={false}
        />
      </div>
      <HandleDashboard />
    </div>
  )
}
