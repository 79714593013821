import React from "react"

import { Password } from "primereact/password"

import { Field } from "formik"

import styles from "../../styles/Form.module.css"

export default function TypePassword(props) {
  const { fieldname, label, description } = props
  return (
    <Field name={fieldname}>
      {({ field, meta }) => {
        return (
          <div className={styles.field}>
            <label htmlFor={fieldname} className={styles.label}>
              {label}
            </label>
            {description && (
              <small style={{ display: "block", marginBottom: 10 }}>
                {description}
              </small>
            )}
            <Password
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              toggleMask
              autoComplete="off"
            />
            {meta.touched && meta.error && (
              <div className={styles.error}>{meta.error}</div>
            )}
          </div>
        )
      }}
    </Field>
  )
}
