import React from "react"
import { DateTime } from "luxon"

export default function Footer() {
  // Get the current year using Luxon
  const currentYear = DateTime.now().year

  return (
    <div className="footer-main">
      <div>
        Third Horizon Strategies {currentYear} ©{" "}
        <a
          href="https://thirdhorizonstrategies.com/terms-of-use/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </a>{" "}
        |{" "}
        <a
          href="https://thirdhorizonstrategies.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  )
}
