import React, { useState, useEffect, useContext } from "react"
import { DateTime } from "luxon"
import { useNavigate } from "react-router-dom"

import { UserContext } from "../../../context/userContext"

// PR
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { SplitButton } from "primereact/splitbutton"

// DataRoom URL
import { dataRoomURL } from "../../../config/cloudFunctionsURL"
import axios from "axios"

// Hooks
import useTriggerDataLayer from "../../../hooks/useTriggerDataLayer"

const baseUrl = dataRoomURL()

export default function ProjectReportsViews() {
  const userCtx = useContext(UserContext)
  const navigate = useNavigate()
  const [triggerDataLayer] = useTriggerDataLayer()
  const [data, setData] = useState([])

  const initialFetch = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/reports/get-report-list`,
        {
          user_id: userCtx.id,
          user_role: String(userCtx.user_role),
          data_region: userCtx.data_region,
        }
      )
      const fetchdata = response.data
      // Format timestamps using Luxon
      const formattedData = fetchdata.reports.map((report) => ({
        ...report,
        timestamp_updated: DateTime.fromISO(
          report.timestamp_updated
        ).toISODate(),
      }))
      setData(formattedData)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    initialFetch()
  }, [])

  const handleDownload = async (report_id, report_name) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/reports/get-report/${report_id}?user_role=${userCtx.user_role}&user_id=${userCtx.id}`,
        { responseType: "blob" }
      )
      const blob = new Blob([response.data], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = `${report_name}.csv`
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      const dataLayerBody = {
        report_id,
        report_name,
      }
      triggerDataLayer("report_download", dataLayerBody)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDelete = async (report_id, report_name) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/reports/delete-report`,
        {
          report_id,
          user_role: String(userCtx.user_role),
        }
      )
      const dataLayerBody = {
        report_id,
        report_name,
      }
      triggerDataLayer("report_delete", dataLayerBody)
      if (response.status === 200) initialFetch()
    } catch (error) {
      console.error(error)
    }
  }

  const handleSlitBody = (rowData) => {
    const items = [
      {
        label: "Download",
        icon: "pi pi-download",
        command: () => {
          const { report_id, report_name } = rowData
          return handleDownload(report_id, report_name)
        },
      },
      {
        label: "Delete",
        icon: "pi pi-times",
        command: () => {
          const { report_id, report_name } = rowData
          handleDelete(report_id, report_name)
        },
      },
    ]

    return (
      <SplitButton
        label="View"
        icon="pi pi-plus"
        onClick={() => navigate(rowData.url)}
        model={items}
      />
    )
  }

  return (
    <DataTable value={data} resizableColumns>
      <Column field="report_name" header="Report Name" />
      <Column field="project_name" header="Project" />
      <Column field="timestamp_updated" header="Last updated" />
      <Column field="permission" header="Visibility" sortable />
      <Column header="Action" body={handleSlitBody} />
    </DataTable>
  )
}
