import React, { useState, useEffect } from "react"
import { DateTime } from "luxon"

import _ from "lodash"
import { useParams } from "react-router-dom"

import axios from "axios"

import cloudFunctionsURL from "../../config/cloudFunctionsURL"
import { GET } from "../../config/constants"

import { Button } from "primereact/button"

// Data Grid
import ReactDataGrid from "@inovua/reactdatagrid-enterprise"

// Loaders
import { LoaderProgressBar } from "../../components/Loaders"

// Filters
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter"
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter"

import DataOrderSummary from "./components/DataOrderSummary"

import "@inovua/reactdatagrid-enterprise/index.css"

export default function DataOrderDetails() {
  const { docId, status } = useParams()

  const [loading, serLoading] = useState(false)

  const [data, setData] = useState([])
  const [dataOrderResults, setDataOrderResults] = useState({})

  const [columns, setColumns] = useState([])
  const [defaultFilterValue, setDefaultFilterValue] = useState([])

  useEffect(() => {
    const URL = `${cloudFunctionsURL()}/${GET.GET_PROCESS_SUMMARY}`
    const fetch = async () => {
      serLoading(true)
      try {
        const response = await axios.post(URL, {
          data_order_id: docId,
          status,
        })
        const handleData = _.map(response.data.data, (row) => {
          return {
            ...row,
            timestamp_updated: DateTime.fromSeconds(
              row.timestamp_updated._seconds
            ).toFormat("F"),
            bq_dataset_url: (
              <Button
                onClick={() => window.open(row.bq_dataset_url, "_blank")}
                label="Connect"
                icon="pi pi-arrow-up-right"
                size="small"
                className="p-button-sm"
              />
            ),
          }
        })
        setData(handleData)
        const handleColumns = _.map(response.data.columns, (column) => {
          if (!_.isEmpty(column.filterEditor)) {
            switch (column.filterEditor) {
              case "SelectFilter":
                column.filterEditor = SelectFilter
                return column
              case "NumberFilter":
                column.filterEditor = NumberFilter
                return column
              case "DateFilter":
                column.filterEditor = "" // not in use.
                return column
              default:
                return column
            }
          }
          return column
        })
        setColumns(handleColumns)
        setDefaultFilterValue(response.data.defaultFilterValue)
        setDataOrderResults(response.data.data_order_results)
        serLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    fetch()
  }, [])

  if (loading) return <LoaderProgressBar />

  const renderRowDetails = ({ data }) => {
    return (
      <div style={{ padding: 20 }}>
        <h3>Row details:</h3>
        <table>
          <tbody>
            {Object.keys(data).map((name, i) => {
              return (
                <tr key={i}>
                  <td>{name}</td>
                  <td>{data[name]}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div>
      <h1>Data Order Details</h1>
      <DataOrderSummary dataOrderResults={dataOrderResults} />
      <ReactDataGrid
        licenseKey={process.env.REACT_APP_RDG}
        idProperty="id"
        pagination
        defaultFilterValue={defaultFilterValue}
        columns={columns}
        style={{ minHeight: "60vh", marginTop: 15 }}
        dataSource={data}
        enableColumnAutosize
        rowExpandHeight={300}
        rowHeight={60}
        renderRowDetails={renderRowDetails}
      />
    </div>
  )
}
