import React from "react"
import { Message } from "primereact/message"

export default function handleStatusMessage(status) {
  switch (status) {
    case "ACTIVE":
      return <Message severity="success" text="Active" />
    case "ARCHIVE":
      return <Message severity="warn" text="Archive" />
    case "PENDING":
      return <Message severity="warn" text="Pending" />
    case "OPEN":
      return <Message severity="info" text="Open to Edit" />
    case "WAITING_IN_CART":
      return <Message severity="info" text="Waiting in Cart" />
    case "IDLE":
      return <Message severity="info" text="Idle" />
    case "INICIATED":
      return <Message severity="info" text="Initiated" />
    case "ON_HOLD":
      return <Message severity="warn" text="On hold" />
    case "DOWNLOADING":
      return <Message severity="warn" text="Downloading" />
    case "DOWNLOAD":
      return <Message severity="info" text="Download" />
    case "DOWNLOADED":
      return <Message severity="success" text="Downloaded" />
    case "COMPRESSED":
      return <Message severity="warn" text="Compressed" />
    case "DECOMPRESSING":
      return <Message severity="warn" text="Decompressing" />
    case "COPYING":
      return <Message severity="warn" text="Copying" />
    case "DONE":
      return <Message severity="success" text="Done" />
    case "PROCESS":
      return <Message severity="info" text="Process" />
    case "PROCESSING":
      return <Message severity="warn" text="Processing" />
    case "PRE_PROCESS":
      return <Message severity="info" text="Pre-Process" />
    case "PROCESSED":
      return <Message severity="success" text="Processed" />
    case "MARKERS_NOT_FOUND":
      return <Message severity="error" text="Markers Not Found" />
    case "MARKERS_FOUND":
      return <Message severity="success" text="Markers Found" />
    case "EXCLUDED":
      return <Message severity="error" text="Excluded" />
    case "ERROR":
      return <Message severity="error" text="Error" />
  }
}
