import React from "react"
import { Field, useFormikContext } from "formik"

import { Editor } from "primereact/editor"

import styles from "../../styles/Form.module.css"

export default function TypeEditor(props) {
  const formik = useFormikContext()
  const { fieldname, label, description } = props

  const renderHeader = () => {
    return (
      <div className="ql-toolbar">
        <span className="ql-formats">
          <button className="ql-bold" aria-label="Bold"></button>
          <button className="ql-italic" aria-label="Italic"></button>
          <button className="ql-underline" aria-label="Underline"></button>
        </span>
        <span className="ql-formats">
          <button
            className="ql-list"
            aria-label="Ordered List"
            value="ordered"
          ></button>
          <button
            className="ql-list"
            aria-label="Unordered List"
            value="bullet"
          ></button>
        </span>
      </div>
    )
  }

  const header = renderHeader()

  return (
    <Field name={fieldname}>
      {({ field, meta }) => {
        return (
          <div className={styles.field}>
            <a id={fieldname} />
            <label htmlFor={fieldname} className={styles.label}>
              {label}
            </label>
            {description && (
              <small style={{ display: "block", marginBottom: 10 }}>
                {description}
              </small>
            )}
            <Editor
              name={fieldname}
              value={field.value}
              onTextChange={({ htmlValue }) =>
                formik.setFieldValue(fieldname, htmlValue)
              }
              headerTemplate={header}
              style={{ height: "180px" }}
              onBlur={field.onBlur}
            />
            {meta.touched && meta.error && (
              <div className={styles.error}>{meta.error}</div>
            )}
          </div>
        )
      }}
    </Field>
  )
}
