export function dataRoomURL() {
  switch (process.env.REACT_APP_STAGE) {
    case "local":
      return process.env.REACT_APP_DATAROOM_LOCAL
    case "develop":
      return process.env.REACT_APP_DATAROOM_DEV
    case "prod":
      return process.env.REACT_APP_DATAROOM_PROD
    default:
      return process.env.REACT_APP_DATAROOM_LOCAL
  }
}

export default function cloudFunctionsURL() {
  switch (process.env.REACT_APP_STAGE) {
    case "local":
      return process.env.REACT_APP_CLOUD_FUNCTIONS_LOCAL
    case "develop":
      return process.env.REACT_APP_CLOUD_FUNCTIONS_DEV
    case "prod":
      return process.env.REACT_APP_CLOUD_FUNCTIONS_PROD
    default:
      return process.env.REACT_APP_CLOUD_FUNCTIONS_LOCAL
  }
}
