import React, { useState, useRef } from "react"
import axios from "axios"
import _ from "lodash"

import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"

// hooks
import useSubscribeAllDocs from "../../hooks/useSubscribeAllDocs"

import cloudFunctionsURL from "../../config/cloudFunctionsURL"
import { DELETE } from "../../config/constants"

// PR
import { Button } from "primereact/button"
import { SplitButton } from "primereact/splitbutton"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Toast } from "primereact/toast"

export default function UserList() {
  const [deleting, setDeleting] = useState(false)
  const hookParams = {
    collection: "users",
    withRef: {
      key: "ref_organization",
      value: ["organization_name"],
    },
  }

  const [loading, error, data] = useSubscribeAllDocs(hookParams)

  const navigate = useNavigate()
  const toast = useRef(null)

  if (error) {
    return (
      <div>
        <h2>Error when fetching.</h2>
      </div>
    )
  }

  const handleDelete = async (email) => {
    setDeleting(true)
    const url = `${cloudFunctionsURL()}/${DELETE.USER_PROFILE}`
    try {
      const response = await axios.post(url, { email })
      if (response.data.status === "success") {
        toast.current.show({
          severity: "success",
          summary: "User Profile Deleted",
          detail: "The user was successfully deleted.",
          life: 3000,
        })
        setDeleting(false)
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error Deleting User",
        detail: "An error occurred while deleting the user.",
        life: 3000,
      })
      setDeleting(false)
    }
  }

  const handleData = _.map(data, (row) => {
    const handleActions = (row) => {
      const { id, email } = row
      const items = [
        {
          label: "Delete",
          icon: "pi pi-times",
          command: () => handleDelete(email),
        },
      ]
      return (
        <SplitButton
          disabled={deleting}
          label="Edit"
          onClick={() => navigate(`/user/edit/${id}`)}
          model={items}
        />
      )
    }

    const handleRoleName = () => {
      switch (row.user_role) {
        case 0:
          return "SysAdmin"
        case 1:
          return "THS Admin"
        case 10:
          return "Analyst"
        case 11:
          return "Authorized Admin"
        case 15:
          return "Authorized User"
        default:
          return "User"
      }
    }

    const modDate = row.timestamp_updated
      ? row.timestamp_updated.toDate()
      : null

    return {
      ...row,
      updated: DateTime.fromJSDate(modDate).toFormat("F"),
      organization_name: row.ref_organization.organization_name,
      user_role: handleRoleName(),
      actions: handleActions(row),
    }
  })

  return (
    <div className="container">
      <h1>Users</h1>

      <Button
        onClick={() => navigate("/user/add")}
        style={{ marginBottom: 15 }}
      >
        Add New User
      </Button>

      <DataTable value={handleData} responsiveLayout="scroll" loading={loading}>
        <Column field="name_first" header="First Name" filter sortable />
        <Column field="name_last" header="Last Name" sortable />
        <Column field="email" header="Email" sortable />
        <Column field="user_role" header="Role" sortable />
        <Column field="organization_name" header="Organization" sortable />
        <Column field="updated" header="Last Update" />
        <Column field="actions" header="Actions" />
      </DataTable>
      <Toast ref={toast} />
    </div>
  )
}
