const ROUTES = {
  // Main
  HOMEPAGE: "/",
  DENIED: "denied",
  // Carrier Directory
  CARRIER_DIRECTORY_ADD: "carrier-directory/add",
  CARRIER_DIRECTORY_LIST: "carrier-directory/list",
  CARRIER_DIRECTORY_EDIT: "carrier-directory/edit",
  // Index Files
  INDEX_FILE_ADD: "index-file/add",
  INDEX_FILE_LIST: "index-file/list",
  INDEX_FILE_EDIT: "index-file/edit",
  // Data Orders
  DATA_ORDER_ADD: "data-order/add",
  DATA_ORDER_LIST: "data-order/list",
  DATA_ORDER_EDIT: "data-order/edit",
  DATA_ORDER_DETAILS: "data-order/details",
  DATA_ORDER_DETAILS_PRE_PROCESS: "data-order/details-pre-process",
  // Projects
  PROJECT_ADD: "project/add",
  PROJECT_EDIT: "project/edit",
  PROJECT_DETAILS: "project/details",
  // MRF
  MRF_LIST: "mrfs/list",
  // Search Index
  SEARCH_INDEX: "search-index/",
  SEARCH_INDEX_SUMMARY: "search-index/summary",
  // Users
  USER_ADD: "user/add",
  USER_LIST: "user/list",
  USER_EDIT: "user/edit",
  // Organizations
  ORG_ADD: "organization/add",
  ORG_LIST: "organization/list",
  ORG_EDIT: "organization/edit",
  // Hospital Directory
  HOSPITAL_DIRECTORY_ADD: "hospital-directory/add",
  HOSPITAL_DIRECTORY_LIST: "hospital-directory/list",
  HOSPITAL_DIRECTORY_EDIT: "hospital-directory/edit",

  //Data Room
  DATA_ROOM: "data-room/",

  COMPARATIVE_COST_MODELS:
    "data-room/analytic-dashboard/comparative-cost-models", // Deprecated
  PROVIDER_NETWORK_CODE_MAP:
    "data-room/provider-network-map/provider-network-code-map", // Deprecated
  BILLING_CODE_ANALYTICS: "data-room/analytic-dashboard/billing-code-analytics", // Deprecated
  CARRIER_NETWORK_ANALYTICS:
    "data-room/analytic-dashboard/carrier-network-analytics", // Deprecated

  //Data Room: Dash A
  COMMERCIAL_INSURANCE_NEGOTIATED_RATES:
    "data-room/analytic-dashboard/commercial-insurance-negotiated-rates",
  // Data Room: Dash B
  PROVIDER_NETWORK_REGIONAL_MAP:
    "data-room/provider-network-map/provider-network-regional-map",
  // Data Room: Dash C
  COMMERCIAL_INSURANCE_CARRIER_NETWORK_COMPARISONS:
    "data-room/network-selection/commercial-insurance-carrier-network-comparisons",
  // Data Room: Dash D
  PRICE_RATE_MODELING_CARRIERS_AND_FACILITY_SYSTEMS:
    "data-room/price-rate-modeling/carriers-and-facility-systems",
}

export default ROUTES
