import React from "react"
import { useNavigate } from "react-router-dom"

// Comps
import FilterBuilder from "./FieldBuilder"

// helper
import objectToUrlParams from "../../../helpers/objectToUrlParams"

import styles from "../../../styles/DataRoom.module.css"

export default function NetworkSelectionScreenOne() {
  const navigate = useNavigate()
  const handleGenerate = (selectedValues) => {
    const paramsString = objectToUrlParams(selectedValues)
    return navigate(
      `/data-room/network-selection/commercial-insurance-carrier-network-comparisons?${paramsString}`
    )
  }
  return (
    <div className={styles.initFilters}>
      <FilterBuilder
        handleGenerate={handleGenerate}
        filterGroupId="network-selection"
        screen={1}
      />
    </div>
  )
}
