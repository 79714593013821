import React, { useEffect, useState } from "react"
import _, { set } from "lodash"
import axios from "axios"
import { InputText } from "primereact/inputtext"
import { Field, useFormikContext } from "formik"

import cloudFunctionsURL from "../../../config/cloudFunctionsURL"
import { GET } from "../../../config/constants"

import styles from "../../../styles/Form.module.css"

export default function MrfOrderAlias(props) {
  const [targetDataset, setTargetDataset] = useState(null)
  const { fieldname, label, description, dataOrderId } = props

  const form = useFormikContext()
  const refCarrierDirectory = form.values.ref_carrier_directory

  useEffect(() => {
    const fetchData = async () => {
      const url = `${cloudFunctionsURL()}/${
        GET.GET_PROJECT_METADATA_FROM_DATA_ORDER
      }`
      const response = await axios.post(url, {
        data_order_id: dataOrderId,
      })
      if (response.data.status === "success") {
        const project_alias = response.data.data.project_alias || ""
        setTargetDataset(project_alias)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (!_.isEmpty(refCarrierDirectory)) {
      form.setFieldValue(fieldname, `${refCarrierDirectory}_`)
    }
  }, [refCarrierDirectory])

  return (
    <Field name={fieldname}>
      {({ field, meta }) => {
        return (
          <div className={styles.field}>
            <label htmlFor={fieldname} className={styles.label}>
              {label}
            </label>
            {description && (
              <small style={{ display: "block", marginBottom: 10 }}>
                {description}
              </small>
            )}
            <InputText
              id={fieldname}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
            />
            {targetDataset && (
              <small style={{ display: "block", marginTop: 5 }}>
                Target Dataset: <strong>{targetDataset}</strong>
              </small>
            )}
            {meta.touched && meta.error && (
              <div className={styles.error}>{meta.error}</div>
            )}
          </div>
        )
      }}
    </Field>
  )
}
