import React from "react"
import { useParams } from "react-router-dom"

import useGetDoc from "../../hooks/useGetDoc"

//comps
import { LoaderSmall } from "../../components/Loaders"
import FormWrapper from "./FormWrapper"

export default function Edit() {
  const { docId } = useParams()
  const [loading, error, data] = useGetDoc("users", docId)
  if (error) {
    return (
      <div>
        <h2>Error when fetching.</h2>
      </div>
    )
  }

  if (loading) return <LoaderSmall />

  const handleData = {
    ...data,
    ref_organization: data.ref_organization.id,
  }

  return (
    <div className="container">
      <h1>Edit User</h1>
      <FormWrapper type="edit" docValues={handleData} />
    </div>
  )
}
