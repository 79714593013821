import React, { useState, useEffect, useRef } from "react"
import { Formik, Form } from "formik"
import "../../styles/Login.css"
import { Toast } from "primereact/toast"
import { InputMask } from 'primereact/inputmask'
import { Dropdown } from 'primereact/dropdown'

import logo from "../../images/logo_starset_analytics_services.png"

import { auth } from "../../firebase"
import * as Yup from "yup"
import {
  RecaptchaVerifier,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  multiFactor,
  getMultiFactorResolver,
} from "firebase/auth"

import { InputText } from "primereact/inputtext"
import { Password } from "primereact/password"
import { Button } from "primereact/button"

const LoginSchema = Yup.object().shape({
  username: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
})

const countryCodes = [
  { label: '+1', value: '+1' },
  { label: '+506', value: '+506' },
  { label: '+52', value: '+52' },
  // Add more country codes as needed
]
const codesDict = [
  {
    id: "+1",
    mask: "(999) 999-9999",
  },
  {
    id: "+52",
    mask: "99-9999-9999",
  },
  {
    id: "+506",
    mask: "9999-9999",
  },
]

export default function Login() {
  const [emailVerified, setEmailVerified] = useState(true)
  const [twoFAEnabled, setTwoFAEnabled] = useState(false)
  const [verifySMSCode, setVerifySMSCode] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [resetPassword, setResetPassword] = useState(false)
  const [emailForReset, setEmailForReset] = useState("")
  const [verificationCode, setVerificationCode] = useState("")
  const [verificationID, setVerificationID] = useState("")
  const [resolver, setResolver] = useState(null)
  const [countryCode, setCountryCode] = useState(countryCodes[0].value)
  const toast = useRef(null)

  const handleMask = () => {
    return codesDict.find(code => code.id === countryCode).mask
  }

  const formatAuthError = (error) => {
    // Remove 'Firebase:' part
    let message = error.replace("Firebase: ", "")
    // Remove text in parentheses
    message = message.replace(/\(.*\)/, "")
    // Remove trailing period
    message = message.replace(/\.\s*$/, "")
    return message.trim()
  }

  const sendVerificationEmail = async () => {
    if (auth.currentUser) {
      await auth.currentUser.sendEmailVerification()
    }
  }

  const sendEmailReset = async () => {
    try {
      await auth.sendPasswordResetEmail(emailForReset)
      toast.current.show([
        {
          life: 20000,
          severity: "info",
          detail: `An email has been sent to ${emailForReset} for password reset`,
        },
      ])
      setResetPassword(false)
    } catch (error) {
      toast.current.show([
        {
          life: 20000,
          severity: "error",
          detail: formatAuthError(error.message),
        },
      ])
    }
  }

  useEffect(() => {
    setUpRecaptcha()
  }, [])

  const setUpRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        },
        auth
      )
      try {
        window.recaptchaVerifier.verify()
      } catch (error) {}
    }
  }

  return (
    <div className="layout">
      <div className="session session-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="session session-form">
        <Toast ref={toast} position="bottom-left"/>
        <div id="recaptcha-container"></div>
        <h1>Login</h1>
        <Formik
          className="login-form"
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={LoginSchema}
          onSubmit={async (values) => {
            const { username: email, password } = values
            try {
              const signIn = await auth.signInWithEmailAndPassword(
                email,
                password
              )
              if (!signIn.user.emailVerified) {
                setEmailVerified(false)
                sendVerificationEmail()
                toast.current.show([
                  {
                    life: 20000,
                    severity: "warn",
                    detail:
                      "Please, verify your email to continue and login again",
                  },
                ])
              } else {
                toast.current.clear()
                setEmailVerified(true)
                // Perform 2FA check if needed
                if (signIn.user.multiFactor.enrolledFactors.length === 0) {
                  toast.current.show([
                    {
                      life: 20000,
                      severity: "warn",
                      detail:
                        "Please, enable a two-factor authentication with your phone number",
                    },
                  ])
                  setTwoFAEnabled(true)
                } else {
                  setTwoFAEnabled(false)
                }
              }
            } catch (error) {
              if (error.code === "auth/multi-factor-auth-required") {
                const resolverInstance = getMultiFactorResolver(auth, error)
                setResolver(resolverInstance)
                if (
                  resolverInstance.hints[0].factorId ===
                  PhoneMultiFactorGenerator.FACTOR_ID
                ) {
                  const phoneInfoOptions = {
                    multiFactorHint: resolverInstance.hints[0],
                    session: resolverInstance.session,
                  }

                  const phoneAuthProvider = new PhoneAuthProvider(auth)
                  const lastFourDigits =
                    resolverInstance.hints[0].phoneNumber.slice(-4)
                  return phoneAuthProvider
                    .verifyPhoneNumber(
                      phoneInfoOptions,
                      window.recaptchaVerifier
                    )
                    .then((verificationID) => {
                      setVerificationID(verificationID)
                      setVerifySMSCode(true)
                      window.recaptchaVerifier.clear()
                      window.recaptchaVerifier = null
                      toast.current.show([
                        {
                          life: 20000,
                          severity: "info",
                          detail: `Provide the verification code that was sent to the phone number ending in: ${lastFourDigits}, to login`,
                        },
                      ])
                    })
                }
              } else {
                toast.current.show([
                  {
                    life: 20000,
                    severity: "error",
                    detail: formatAuthError(error.message),
                  },
                ])
              }
            }
          }}
        >
          {(formik) => {
            const { errors, touched, values, setFieldValue } = formik
            return (
              <Form style={{ margin: "15px 30px" }}>
                <span className="p-float-label">
                  <InputText
                    id="username"
                    name="username"
                    required
                    value={values.username}
                    disabled={verifySMSCode}
                    onChange={({ target: { value } }) =>
                      setFieldValue("username", value)
                    }
                  />
                  <label>Username</label>
                  {touched.username && errors.username && (
                    <div style={{ color: "#ff678c" }}>{errors.username}</div>
                  )}
                </span>
                <span className="p-float-label">
                  <Password
                    inputId="password"
                    name="password"
                    value={values.password}
                    disabled={verifySMSCode}
                    onChange={({ target: { value } }) =>
                      setFieldValue("password", value)
                    }
                  />
                  <label htmlFor="password">Password</label>
                </span>
                {touched.password && errors.password && (
                  <div style={{ color: "#ff678c" }}>{errors.password}</div>
                )}
                <Button type="submit" disabled={verifySMSCode}>Login</Button>
                <span
                  onClick={() => setResetPassword(true)}
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Reset password
                </span>
                {resetPassword && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h3>Please provide your email for password reset</h3>
                    <span className="p-float-label">
                      <InputText
                        id="emailForReset"
                        name="emailForReset"
                        value={emailForReset}
                        onChange={({ target: { value } }) =>
                          setEmailForReset(value)
                        }
                      />
                      <label>Your email</label>
                    </span>
                    <Button
                      style={{ marginTop: "10px" }}
                      type="button"
                      onClick={sendEmailReset}
                    >
                      Send reset instructions
                    </Button>
                  </div>
                )}
                {!emailVerified && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Button onClick={sendVerificationEmail}>
                      Resend Verification Email
                    </Button>
                  </div>
                )}
                {twoFAEnabled && !verifySMSCode && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h3>Two-factor Authentication</h3>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Dropdown
                        value={countryCode}
                        options={countryCodes}
                        onChange={(e) => setCountryCode(e.value)}
                        placeholder="Select a Country Code"
                      />
                        <InputMask
                          id="phone"
                          name="phone"
                          value={phoneNumber}
                          onChange={({ target: { value } }) =>
                            setPhoneNumber(value)
                          }
                          mask={handleMask()}
                          placeholder={handleMask()}
                        />
                    </div>
                    <Button
                      type="button"
                      style={{ marginTop: "10px" }}
                      onClick={async () => {
                        const user = auth.currentUser
                        multiFactor(user)
                          .getSession()
                          .then(function (multiFactorSession) {

                            const phoneInfoOptions = {
                              phoneNumber: countryCode + phoneNumber,
                              session: multiFactorSession,
                            }

                            const phoneAuthProvider = new PhoneAuthProvider(
                              auth
                            )
                            phoneAuthProvider
                              .verifyPhoneNumber(
                                phoneInfoOptions,
                                window.recaptchaVerifier
                              )
                              .then(function (verificationID) {
                                setVerificationID(verificationID)
                                setVerifySMSCode(true)
                                const cleanedPhoneNumber = phoneNumber.replace(/\s+/g, '')
                                const lastFourDigits = cleanedPhoneNumber.slice(-4)

                                toast.current.show([
                                  {
                                    life: 20000,
                                    severity: "info",
                                    detail: `Provide the verification code that was sent to the phone number ending in: ${lastFourDigits}, to activate two-factor authentication`,
                                  },
                                ])
                              })
                              .catch(function (error) {
                                toast.current.show([
                                  {
                                    life: 20000,
                                    severity: "error",
                                    detail: formatAuthError(error.message),
                                  },
                                ])
                              })
                          })
                      }}
                    >
                      Verify Phone Number
                    </Button>
                  </div>
                )}
                {verifySMSCode && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span className="p-float-label">
                      <InputText
                        id="verificationCode"
                        name="verificationCode"
                        value={verificationCode}
                        onChange={({ target: { value } }) =>
                          setVerificationCode(value)
                        }
                      />
                      <label>Verification Code</label>
                    </span>
                    <Button
                      type="button"
                      style={{ marginTop: "10px" }}
                      onClick={async () => {
                        const cred = PhoneAuthProvider.credential(
                          verificationID,
                          verificationCode
                        )
                        const multiFactorAssertion =
                          PhoneMultiFactorGenerator.assertion(cred)
                        if (!twoFAEnabled) {
                          try {
                            await resolver.resolveSignIn(multiFactorAssertion)
                            window.recaptchaVerifier.clear()
                          } catch (error) {
                            toast.current.show([
                              {
                                life: 20000,
                                severity: "error",
                                detail: formatAuthError(error.message),
                              },
                            ])
                          }
                        } else {
                          const user = auth.currentUser
                          multiFactor(user)
                            .enroll(
                              multiFactorAssertion,
                              "Personal phone number"
                            )
                            .then(function () {
                              setVerificationCode("")
                              setTwoFAEnabled(false)
                              setVerifySMSCode(false)
                              toast.current.show([
                                {
                                  life: 20000,
                                  severity: "success",
                                  detail:
                                    "Two-factor Authentication activated, please login to your account again and enter verification code",
                                },
                              ])
                              auth.signOut()
                            })
                            .catch(function (error) {
                              toast.current.show([
                                {
                                  life: 20000,
                                  severity: "error",
                                  detail: formatAuthError(error.message),
                                },
                              ])
                            })
                        }
                      }}
                    >
                      Enter Verification Code
                    </Button>
                  </div>
                )}
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}
