import React, { useState, useEffect } from "react"
import axios from "axios"
import _ from "lodash"

import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"

// hooks
import useGetAllDocs from "../../hooks/useSubscribeAllDocs"

// PR
import { Button } from "primereact/button"
import { SplitButton } from "primereact/splitbutton"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

// CONST
import { ENTITY, GET } from "../../config/constants"

// comps
import handleStatusMessage from "../../helpers/handleStatusMessage"
import { LoaderSmall } from "../../components/Loaders"

import cloudFunctionsURL from "../../config/cloudFunctionsURL"

export default function MRFList() {
  const [processData, setProcessData] = useState([])
  const [fetching, setFetching] = useState(true)
  const [loading, error, data] = useGetAllDocs({
    collection: ENTITY.MRF,
  })
  const navigate = useNavigate()

  useEffect(() => {
    if (loading) return
    handleData()
  }, [loading, data])

  const handleMrfOrderAliasList = async (mrfs_id) => {
    try {
      const url = `${cloudFunctionsURL()}/${GET.GET_MRF_ORDER_BY_REF}`
      const response = await axios.post(url, {
        mrfs_id,
      })
      if (response.data.status === "success") {
        const aliasList = _.map(response.data.data, (order, i) => (
          <li key={i} style={{ display: "block" }}>
            {order.mrf_order_alias}
          </li>
        ))
        return <ul style={{ margin: 0, padding: 0 }}>{aliasList}</ul>
      }
    } catch (error) {
      setFetching(false)
      console.log("Error when getting list of MRF Order Aliases", error)
    }
  }

  const handleData = async () => {
    const newData = await Promise.all(
      _.map(data, async (row) => {
        const handleActions = (docId) => {
          const items = [
            {
              label: "Delete",
              icon: "pi pi-times",
              command: () => {},
            },
          ]
          return (
            <SplitButton
              label="Edit"
              onClick={() => navigate(`/mrf/edit/${docId}`)}
              model={items}
              disabled={row.mrf_index_file_status === "downloading"}
            />
          )
        }

        const mrfAliasList = await handleMrfOrderAliasList(row.id)

        return {
          ...row,
          mrf_status: handleStatusMessage(row.mrf_status),
          data_order_mrf_aliases: mrfAliasList,
          timestamp_updated: DateTime.fromJSDate(
            row.timestamp_updated.toDate()
          ).toFormat("F"),
          actions: handleActions(row.id),
        }
      })
    )
    setProcessData(newData)
    setFetching(false)
  }

  if (error) {
    return (
      <div>
        <h2>Error when fetching.</h2>
      </div>
    )
  }

  if (loading || fetching) {
    return <LoaderSmall />
  }

  return (
    <div className="container">
      <h1>MRF Inventory</h1>
      <DataTable value={processData} responsiveLayout="scroll">
        <Column
          field="data_order_mrf_aliases"
          header="Data Order MRF Aliases"
          filter
        />
        <Column field="id" header="Id" />
        <Column field="mrf_type" header="Type" sortable />
        <Column field="mrf_status" header="MRF Status" />
        <Column field="timestamp_updated" header="Updated Date" sortable />
      </DataTable>
    </div>
  )
}
