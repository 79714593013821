import React, { useState } from "react"
import _ from "lodash"

// HC Dashboard
import Dashboard from "../../components/Dashboard"

// PR
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"

export default function Bar({ payload }) {
  const [currentData, setCurrentData] = useState(payload.data)

  if (_.isEmpty(payload)) {
    return <div>No results found.</div>
  }

  let configDynamic = payload.dashboard

  function HandleDashboard() {
    const setConnector = (connectorId, data) => {
      const connector = _.find(configDynamic.dataPool.connectors, {
        id: connectorId,
      })
      _.set(connector, "options.data", data)
    }

    const setCategories = () => {
      const component = _.find(configDynamic.components, {
        cell: "dashboard-col-1",
      })
      component.chartOptions.xAxis.categories = currentData
        .map((row) => row[0])
        .slice(1)
    }

    // Set data to connector
    setConnector("connector-1", currentData)

    setCategories()

    return <Dashboard config={configDynamic} />
  }

  return (
    <div
      style={{
        borderTop: "1px solid lightgrey",
        paddingTop: 15,
        marginTop: 15,
      }}
    >
      <HandleDashboard />
    </div>
  )
}
