import { useContext } from "react"
import { UserContext } from "../context/userContext"

export default function useTriggerDataLayer() {
  const userCtx = useContext(UserContext)
  function triggerDataLayer(event, data) {
    try {
      window.dataLayer.push({
        event,
        ...userCtx,
        ...data,
      })
    } catch (error) {
      console.log("Error triggering Data Layer.", error)
    }
  }
  return [triggerDataLayer]
}
