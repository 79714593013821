import React from "react"
import { useParams, useNavigate } from "react-router-dom"

import useGetDoc from "../../hooks/useGetDoc"

//comps
import { LoaderSmall } from "../../components/Loaders"
import FormWrapper from "./FormWrapper"

export default function Edit() {
  const { docId } = useParams()
  const [loading, error, data] = useGetDoc("projects", docId)
  if (error) {
    return (
      <div>
        <h2>Error when fetching.</h2>
      </div>
    )
  }
  if (loading) return <LoaderSmall />
  return (
    <div className="container">
      <h1>Edit Project</h1>
      <FormWrapper type="edit" docValues={data} />
    </div>
  )
}
