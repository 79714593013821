import React from "react"
import _ from "lodash"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

export default function DataTableRank(props) {
  const { data, geoNameLabel, isNegocitated, isProvider } = props

  const handleColumns = () => {
    const multiLineColumn = (row, field) => {
      const currrentField = row[field]
      const fieldArray = currrentField.split(",")
      return (
        <div>
          {fieldArray.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      )
    }
    const handleColumn = (columns) =>
      _.map(columns, (col) => {
        const isCurrency =
          col.field === "mean" || col.field === "min" || col.field === "max"
        if (isCurrency) {
          return (
            <Column
              sortable={true}
              filter={true}
              field={col.field}
              header={col.header}
              key={col.field}
              body={(rowData) => {
                let USDollar = new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                })
                return USDollar.format(rowData[col.field])
              }}
            />
          )
        }
        if (col.body === "multiLineColumn") {
          return (
            <Column
              sortable={true}
              filter={true}
              field={col.field}
              header={col.header}
              key={col.field}
              body={(row) => multiLineColumn(row, col.field)}
            />
          )
        }
        return (
          <Column
            sortable={true}
            filter={true}
            field={col.field}
            header={col.header}
            key={col.field}
          />
        )
      })

    if (isProvider) {
      const columns = [
        {
          field: "state_value",
          header: props.mapLayer === "county_name" ? "County" : "CBSA-MSA",
        },
        {
          field: "carrier_plan_name",
          header: "Carrier Plan",
        },
        {
          field: "avg_z_score",
          header: "AVG Z-score",
        },
      ]
      return handleColumn(columns)
    }
    if (isNegocitated) {
      const columns = [
        {
          field: "geo_name",
          header: geoNameLabel,
        },
        {
          field: "carrier_plan_name",
          header: "Carrier Plan Name",
        },
        {
          field: "facility_system_primary",
          header: "Facility System",
        },
        {
          field: "mean",
          header: "Mean",
        },
        {
          field: "min",
          header: "Min",
        },
        {
          field: "max",
          header: "Max",
        },
      ]
      return handleColumn(columns)
    }
  }

  return (
    <div className="datatablerank">
      <DataTable
        value={data}
        paginator
        rows={20}
        rowsPerPageOptions={[10, 20, 50, 80]}
        tableStyle={{ minWidth: "50rem" }}
      >
        {handleColumns()}
      </DataTable>
    </div>
  )
}
