import React, { useEffect, useState } from "react"
import axios from "axios"
import { Field } from "formik"
import { Dropdown } from "primereact/dropdown"

import cloudFunctionsURL from "../../../config/cloudFunctionsURL"

import _ from "lodash"

// constants
import { GET } from "../../../config/constants"

import styles from "../../../styles/Form.module.css"
import { LoaderSkeleton } from "../../Loaders"

export default function RefOrganization(props) {
  const [fetching, setFetching] = useState(true)
  const { fieldname, label } = props
  const [organizationList, setOrganizationList] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const url = `${cloudFunctionsURL()}/${GET.ORGANIZATION_LIST}`
      const result = await axios.post(url, {
        type: "dropdown",
      })
      setOrganizationList(result.data.data)
      setFetching(false)
    }
    fetchData()
  }, [])

  if (fetching) return <LoaderSkeleton count={1} />

  return (
    <Field name={fieldname}>
      {({ field, meta }) => {
        return (
          <div className={styles.field}>
            <label htmlFor={fieldname} className={styles.label}>
              {label}
            </label>
            <Dropdown
              id={fieldname}
              options={organizationList}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
            />
            {meta.touched && meta.error && (
              <div className={styles.error}>{meta.error}</div>
            )}
          </div>
        )
      }}
    </Field>
  )
}
