import React from "react"

import {
  AiOutlineFall,
  AiOutlineRise,
  AiOutlineInfoCircle,
  AiOutlineInteraction,
} from "react-icons/ai"

export default function Insights(props) {
  const InsightOne = () => (
    <>
      <h3>Key Insights</h3>
      <div className="insights-section-grid">
        <div className="p-card average-rate">
          <h3>
            <AiOutlineInfoCircle />
            Average Rate
          </h3>
          <span className="price">$2230.74</span>
          <p>Clinical Category</p>
        </div>
        <div className="p-card lowest-rate">
          <h3>
            <AiOutlineFall />
            Lowest Rate
          </h3>
          <span className="price">$1966.42</span>
          <p>Clinical Category</p>
        </div>
        <div className="p-card highest-rate">
          <h3>
            <AiOutlineRise />
            Highest Rate
          </h3>
          <span className="price">$2780.95</span>
          <p>Clinical Category</p>
        </div>
        <div className="p-card closest-competitor">
          <h3>
            <AiOutlineInteraction />
            Closest Competitor
          </h3>
          <p>Name of Competing Carrier</p>
          <p>90% Coverage</p>
        </div>
      </div>
    </>
  )

  const InsightTwo = () => (
    <>
      <h3>Regional Comparison</h3>
      <p>
        This section provides an analysis of average rates across various
        regions. Our data reveals significant disparities in pricing and
        coverage, indicating opportunities for improvement.
      </p>
      <div className="insights-section-grid">
        <div className="p-card">
          <strong>North Region:</strong> Average Rate: $2100.00, Lowest Rate:
          $1950.00, Highest Rate: $2400.00
        </div>
        <div className="p-card">
          <strong>South Region:</strong> Average Rate: $2300.00, Lowest Rate:
          $2150.00, Highest Rate: $2500.00
        </div>
        <div className="p-card">
          <strong>East Region:</strong> Average Rate: $2400.00, Lowest Rate:
          $2200.00, Highest Rate: $2600.00
        </div>
        <div className="p-card">
          <strong>West Region:</strong> Average Rate: $2500.00, Lowest Rate:
          $2300.00, Highest Rate: $2700.00
        </div>
      </div>
      <p>
        Understanding these regional differences is crucial for strategic
        pricing and marketing initiatives.
      </p>
    </>
  )

  const InsightThree = () => (
    <>
      <h3>Price/Quality Matrix</h3>
      <p>
        The Price/Quality Matrix helps visualize the relationship between
        service pricing and quality ratings across various providers.
      </p>
      <div className="insights-section-grid">
        <div className="p-card">
          <strong>Provider A:</strong> Price: $2200, Quality Score: 4.5/5
        </div>
        <div className="p-card">
          <strong>Provider B:</strong> Price: $2000, Quality Score: 4.0/5
        </div>
        <div className="p-card">
          <strong>Provider C:</strong> Price: $2500, Quality Score: 4.8/5
        </div>
        <div className="p-card">
          <strong>Provider D:</strong> Price: $2100, Quality Score: 4.2/5
        </div>
      </div>
      <p>
        This matrix enables stakeholders to identify potential gaps in the
        market and adjust their strategies accordingly.
      </p>
    </>
  )

  switch (props.insight) {
    case 1:
      return <InsightOne />
    case 2:
      return <InsightTwo />
    case 3:
      return <InsightThree />
    default:
      return null
  }
}
