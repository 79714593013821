import React, { useEffect } from "react"
import _ from "lodash"

// PR
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

// HC: Density
import Highcharts from "highcharts"
import HighchartsMore from "highcharts/highcharts-more"
import HighchartsData from "highcharts/modules/data"

HighchartsMore(Highcharts)
HighchartsData(Highcharts)

export default function Density({ payload }) {
  useEffect(() => {
    const config = {
      chart: {
        type: "areasplinerange",
        animation: true,
      },
      title: {
        text: "Density Plot",
      },
      xAxis: {
        labels: { format: "${value}" },
      },
      yAxis: {
        title: { text: null },
        categories: payload.categories,
        labels: {
          formatter: function () {
            if (this.pos < payload.chartsNbr) return this.value
          },
          style: {
            textTransform: "capitalize",
            fontSize: "9px",
          },
        },
        startOnTick: true,
        gridLineWidth: 1,
        tickmarkPlacement: "on",
      },
      tooltip: {
        useHTML: true,
        shared: true,
        crosshairs: true,
        valueDecimals: 3,
        headerFormat: null,
        // pointFormatter: function () {
        //   console.log(this)
        //   return `<b>${this.series.name}</b>: ${this.x} kg, Frequency: ${this.frequency} <br/>`
        // },
        footerFormat: null,
      },
      plotOptions: {
        areasplinerange: {
          marker: {
            enabled: false,
          },
          states: {
            hover: {
              enabled: false,
            },
          },
          pointStart: payload.pointStart,
          animation: {
            duration: 0,
          },
          fillColor: "",
          lineWidth: 1,
          color: "black",
        },
      },
      legend: {
        enabled: false,
      },
      series: payload.series,
    }

    Highcharts.chart("container", config)
  }, [payload])

  const allStatsContent = () => {
    const stats = _.map(payload.series, (serie) => {
      return {
        code: serie.name,
        min: _.round(serie.stats[0], 2),
        q1: _.round(serie.stats[1], 2),
        median: _.round(serie.stats[2], 2),
        q3: _.round(serie.stats[3], 2),
        max: _.round(serie.stats[4], 2),
        geomean: _.round(serie.stats[5], 2),
      }
    })
    return (
      <DataTable value={stats} tableStyle={{ minWidth: "50rem" }}>
        <Column field="code" header="Code" filter sortable />
        <Column field="min" header="Min" sortable />
        <Column field="q1" header="Q1" sortable />
        <Column field="median" header="Median" sortable />
        <Column field="q3" header="Q3" sortable />
        <Column field="max" header="Max" sortable />
        <Column field="geomean" header="Geomean" sortable />
      </DataTable>
    )
  }

  return (
    <div>
      <div id="container" />
      <br />
      {allStatsContent()}
    </div>
  )
}
