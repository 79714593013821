import React from "react"
import styles from "../../styles/DataRoom.module.css"

// Comps: Selectors
import CINR from "./components/CINR"
import PNRM from "./components/PNRM"

import CICNC from "./components/CICNC"
import PRM from "./components/PRM"

export default function DataRoom() {
  return (
    <div>
      <h1>Data Room</h1>
      <p>
        Visualization and reporting for Transparency in Coverage data is
        available in dashboard plots and geo-mapping formats.
      </p>
      <p>
        Apply filters below to set initial dataset parameters and then select
        Visualize to launch the reporting tool.
      </p>

      <div className={`${styles.layout} data-room-landing`}>
        <div>
          <h2>
            <span className="material-icons">hub</span> Network Selection
          </h2>

          <div className={`${styles.dataProduct} ${styles.purple}`}>
            <h4>Commercial Insurance Carrier Network Comparisons</h4>
            <CICNC />
          </div>
        </div>

        <div>
          <h2>
            <span className="material-icons">analytics</span> Price Rate
            Modeling
          </h2>
          <div className={`${styles.dataProduct} ${styles.purple}`}>
            <h4>Price Rate Modeling from Carriers and Facility Systems</h4>
            <PRM />
          </div>
        </div>

        <div>
          <h2>
            <span className="material-icons">analytics</span>Analytic Dashboard
          </h2>
          <div className={`${styles.dataProduct} ${styles.yellow}`}>
            <h4>
              Commercial Insurance Negotiated Rates by Billing Codes and Carrier
            </h4>
            <CINR />
          </div>
        </div>

        <div>
          <h2>
            <span className="material-icons">public</span>Provider Network Map
          </h2>
          <div className={`${styles.dataProduct} ${styles.yellow}`}>
            <h4>Provider Network Regional Map</h4>
            <PNRM />
          </div>
        </div>
      </div>
    </div>
  )
}
