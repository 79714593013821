import React from "react"

import { HashLink as Link } from "react-router-hash-link"

import _ from "lodash"
import { TabView, TabPanel } from "primereact/tabview"
import { DateTime } from "luxon"

// Helpers
import handleStatusMessage from "../../../helpers/handleStatusMessage"

export default function DataOrderSummary({ dataOrderResults }) {
  const handleDataOrderResults = () => {
    if (_.isEmpty(dataOrderResults)) return null
    return (
      <div>
        <h3>
          {`${dataOrderResults.project_name}, ${dataOrderResults.data_order_name}`}
        </h3>
        <div>
          <strong>Data Order Last Update:</strong>{" "}
          {DateTime.fromSeconds(
            dataOrderResults.data_order_datetime_updated._seconds
          ).toFormat("ff")}
        </div>
        <div style={{ marginBottom: 10 }}>
          <strong>Data Order ID:</strong> {dataOrderResults.data_order_id}
        </div>
        <div>
          <strong>Project Dates:</strong> {dataOrderResults.project_timeline}
        </div>
        <div>
          <strong>Project Lead(s):</strong>{" "}
          {dataOrderResults.project_data_team_lead}
        </div>
        <div style={{ marginTop: 20 }}>
          {handleStatusMessage(dataOrderResults.data_order_status)}
        </div>
      </div>
    )
  }

  const handleHtmlValues = (value, label, anchor) => {
    if (_.isEmpty(value)) {
      const { project_alias } = dataOrderResults
      return (
        <div>
          Add{" "}
          <Link to={`/project/edit/${project_alias}#${anchor}`}>{label}</Link>
        </div>
      )
    }
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    )
  }

  return (
    <TabView>
      <TabPanel header="Summary">{handleDataOrderResults()}</TabPanel>
      <TabPanel header="Project Overview">
        {handleHtmlValues(
          dataOrderResults.project_overview,
          "Project Overview",
          "project_overview"
        )}
      </TabPanel>
      <TabPanel header="Project Deliverables">
        {handleHtmlValues(
          dataOrderResults.project_deliverables,
          "Project Deliverables",
          "project_data_product_deliverables"
        )}
      </TabPanel>
      <TabPanel header="Data Analytics Requirements">
        {handleHtmlValues(
          dataOrderResults.project_data_analytics_requirements,
          "Data Analytics Requirements",
          "project_data_analytics_requirements"
        )}
      </TabPanel>
      <TabPanel header="MRF Plan Details">
        {handleHtmlValues(
          dataOrderResults.project_mrf_plan_details,
          "MRF Plan Details",
          "project_mrf_plan_details"
        )}
      </TabPanel>
    </TabView>
  )
}
