import React from "react"

import { HashLink as Link } from "react-router-hash-link"

import _ from "lodash"
import { TabView, TabPanel } from "primereact/tabview"
import { DateTime } from "luxon"

// Helpers
import handleStatusMessage from "../../../helpers/handleStatusMessage"

export default function ProjectSummary({ projectMetadata }) {
  const handleprojectMetadata = () => {
    if (_.isEmpty(projectMetadata)) return null
    return (
      <div>
        <h2>{projectMetadata.project_name}</h2>
        <div>
          <strong>Project Last Update:</strong>{" "}
          {DateTime.fromSeconds(
            projectMetadata.project_datetime_updated._seconds
          ).toFormat("ff")}
        </div>
        <div>
          <strong>Project Dataset Name:</strong> {projectMetadata.project_bi}
        </div>
        <div>
          <strong>Project Dates:</strong> {projectMetadata.project_timeline}
        </div>
        <div>
          <strong>Project Lead(s):</strong>{" "}
          {projectMetadata.project_data_team_lead}
        </div>
        <div style={{ marginTop: 20 }}>
          {handleStatusMessage(projectMetadata.data_order_status)}
        </div>
      </div>
    )
  }

  const handleHtmlValues = (value, label, anchor) => {
    if (_.isEmpty(value)) {
      const { project_alias } = projectMetadata
      return (
        <div>
          Add{" "}
          <Link to={`/project/edit/${project_alias}#${anchor}`}>{label}</Link>
        </div>
      )
    }
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    )
  }

  return (
    <TabView>
      <TabPanel header="Summary">{handleprojectMetadata()}</TabPanel>

      <TabPanel header="Project Overview">
        {handleHtmlValues(
          projectMetadata.project_overview,
          "Project Overview",
          "project_overview"
        )}
      </TabPanel>

      <TabPanel header="Project Deliverables">
        {handleHtmlValues(
          projectMetadata.project_deliverables,
          "Project Deliverables",
          "project_data_product_deliverables"
        )}
      </TabPanel>

      <TabPanel header="Data Analytics Requirements">
        {handleHtmlValues(
          projectMetadata.project_data_analytics_requirements,
          "Data Analytics Requirements",
          "project_data_analytics_requirements"
        )}
      </TabPanel>

      <TabPanel header="MRF Plan Details">
        {handleHtmlValues(
          projectMetadata.project_mrf_plan_details,
          "MRF Plan Details",
          "project_mrf_plan_details"
        )}
      </TabPanel>
    </TabView>
  )
}
