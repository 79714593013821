import React from "react"
import { Panel } from "primereact/panel"

import { Button } from "primereact/button"
import { useNavigate } from "react-router-dom"
import styles from "../../../styles/Home.module.css"

// comps
import ProjectsAndDataOrders from "../components/ProjectsAndDataOrders"

export default function Workbench() {
  const navigate = useNavigate()
  return (
    <div className="homepage">
      <h1>Data Room Workbench</h1>
      <div className={styles.layout}>
        <div>
          <div className="box">
            <Panel header="Insurance Carrier Directory" toggleable>
              <p className="m-0 mb-3">
                Group health plans, health insurance issuers, and third-party
                administrators that manage publishing machine-readable files.
              </p>

              <Button
                label="Add Carrier"
                onClick={() => navigate("/carrier-directory/add")}
                className="p-button-sm"
                style={{ marginRight: 5 }}
              />

              <Button
                label="Carrier Directory"
                onClick={() => navigate("/carrier-directory/list")}
                className="p-button-sm p-button-outlined"
                outlined
              />
            </Panel>
            <br />
            <Panel header="Index Management System" toggleable>
              <p className="m-0 mb-3">
                View, manage and order processing of insurance provider index
                machine-readable files for TIC projects.
              </p>

              <Button
                label="Add Index File"
                onClick={() => navigate("/index-file/add")}
                className="p-button-sm"
                style={{ marginRight: 5 }}
              />

              <Button
                label="Index Repository"
                onClick={() => navigate("/index-file/list")}
                className="p-button-sm p-button-outlined"
              />
            </Panel>
            <br />
            <Panel header="Provider Directory API" toggleable>
              <p className="m-0 mb-3">
                Provider-related resources include insurance plan information,
                location, services and networks required to deliver provider
                directory information.
              </p>
              <Button
                label="Add API Endpoint"
                className="p-button-sm"
                style={{ marginRight: 5 }}
              />
              <Button
                label="Access Plan APIs"
                className="p-button-sm p-button-outlined"
              />
            </Panel>
          </div>
        </div>

        <div>
          <div
            className="box"
            style={{ marginBottom: 30, overflow: "scroll", height: "100%" }}
          >
            <ProjectsAndDataOrders />
          </div>
        </div>
      </div>
    </div>
  )
}
