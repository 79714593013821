import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import _ from "lodash"

import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

import axios from "axios"

import MarkerFileUpload from "./MarkerFileUpload"

import cloudFunctionsURL from "../../config/cloudFunctionsURL"
import { GET, SET } from "../../config/constants"
import handleStatusMessage from "../../helpers/handleStatusMessage"

// Loaders
import { LoaderProgressBar } from "../../components/Loaders"

// PR
import { Button } from "primereact/button"

export default function DataOrderSummary() {
  const navigate = useNavigate()

  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [mrfOrders, setMrfOrders] = useState({})
  const { dataOrder } = useParams()

  useEffect(() => {
    const URL = `${cloudFunctionsURL()}/${GET.DATA_ORDER_SUMMARY}`
    const fetch = async () => {
      setLoading(true)
      try {
        const response = await axios.post(URL, { data_order_id: dataOrder })
        const mrf_orders = response.data.data.mrf_orders || {}
        setMrfOrders(mrf_orders)
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    fetch()
  }, [])

  const handleDataTables = () =>
    _.map(mrfOrders, (values, key) => {
      const handleFilename = (location) => {
        try {
          const pathArray = new URL(location).pathname.split("/")
          const filenameWithExtension = pathArray.pop()
          return filenameWithExtension
        } catch (error) {
          console.log(error)
        }
      }
      const handleValues = _.map(values, (row) => {
        const locationUrl =
          row.mrf_source_download_location || row.mrf_source_location
        return {
          ...row,
          mrf_order_status: handleStatusMessage(row.mrf_order_status),
          mrf_order_stage: handleStatusMessage(row.mrf_order_stage),
          filename: handleFilename(locationUrl),
        }
      })
      return (
        <div key={key}>
          <h2>{key}</h2>
          <DataTable value={handleValues} responsiveLayout="scroll">
            <Column field="mrf_order_alias" header="Alias" />
            <Column field="filename" header="Filename" />
            <Column field="mrf_order_stage" header="Stage" />
            <Column field="mrf_order_status" header="Status" />
          </DataTable>
        </div>
      )
    })

  if (loading) return <LoaderProgressBar />

  const handleContinueSearch = () => {
    const currentSearch = sessionStorage.getItem("search")
    if (_.isEmpty(currentSearch)) {
      return navigate("/data-order/list")
    }
    return navigate(`/${currentSearch}`)
  }

  const handlePlaceOrder = async (type, options = {}) => {
    setSubmitting(true)
    const placerOrderURL = `${cloudFunctionsURL()}/${SET.PLACE_ORDER}`
    const handleParams = () => {
      switch (type) {
        case "process":
          return {
            data_order_id: dataOrder,
            process_type: type,
          }
        case "pre-process":
          return {
            data_order_id: dataOrder,
            process_type: type,
            markers_file_options: options,
          }
      }
    }
    await axios.post(placerOrderURL, handleParams())
    setSubmitting(false)
    return navigate("/data-order/list")
  }

  return (
    <div>
      <h1>Summary</h1>
      <div style={{ display: "flex", gap: 15 }}>
        <Button
          onClick={() => handleContinueSearch()}
          label="Continue Searching"
          disabled={submitting}
        />

        <MarkerFileUpload
          submitting={submitting}
          dataOrder={dataOrder}
          handlePlaceOrder={handlePlaceOrder}
        />

        <Button
          onClick={() => handlePlaceOrder("process")}
          label="Place Order to Process"
          disabled={submitting}
        />
      </div>
      {handleDataTables()}
    </div>
  )
}
