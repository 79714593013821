import React, { useContext } from "react"

import { UserContext } from "../../../context/userContext"

import { useNavigate } from "react-router-dom"
import { getAuth } from "firebase/auth"

import logoHFMA from "../../../images/logo_hfma_white.png"

import { AiOutlineUser, AiFillSetting, AiOutlineLogout } from "react-icons/ai"

export default function Header() {
  const navigate = useNavigate()

  const { name_first, name_last, organization_name } = useContext(UserContext)
  const fullName = `${name_first} ${name_last}`
  return (
    <div className="hfma-header">
      <div className="hfma-container py-4">
        <div className="flex overflow-hidden">
          <img
            src={logoHFMA}
            alt="Healthcare Financial Management Association"
            height={60}
            className="m-0"
          />
          <div className="flex-grow-1 text-center">
            <h2>Price Transparency Dashboard</h2>
          </div>
          <div className="flex flex-column gap-2">
            <div className="flex justify-content-end align-items-center gap-2">
              <AiOutlineUser />
              <AiFillSetting />
              <div
                onClick={async () => {
                  await getAuth().signOut()
                  navigate("/")
                }}
                className="flex gap-1"
                style={{ cursor: "pointer" }}
              >
                <AiOutlineLogout />
                <span className="text-xs">Logout</span>
              </div>
            </div>
            <div>
              {fullName} | {organization_name}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
