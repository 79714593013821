import React, { useEffect, useState } from "react"
import _ from "lodash"
import { Field, useFormikContext } from "formik"

import { DateTime } from "luxon"

import { InputText } from "primereact/inputtext"
import { InputMask } from "primereact/inputmask"

import styles from "../../../styles/Form.module.css"

export default function MrfDate(props) {
  const [errorMsg, setErrorMsg] = useState(null)
  const [manualInput, setManualInput] = useState(false)
  const form = useFormikContext()

  const {
    mrf_source_download_location,
    mrf_source_location,
    mrf_source_manual_input,
  } = form.values

  const isMultiple = mrf_source_manual_input === "mrf_source_3"

  const fileLocation = mrf_source_download_location || mrf_source_location
  const fileLocationTouched =
    form.touched.mrf_source_download_location ||
    form.touched.mrf_source_location

  const fileLocationHasError = !_.isEmpty(
    form.errors.mrf_source_download_location
  )

  useEffect(() => {
    if (isMultiple) return setManualInput(true)

    const getDate = () => {
      let candidateDate = null
      try {
        const url = new URL(fileLocation)
        const urlSplit = _.split(url.pathname, "/")
        const filepath = urlSplit[urlSplit.length - 1].replace("_", "-")
        const candidateDates = [
          filepath.substring(0, 10),
          filepath.substring(0, 7),
        ]
        for (let candidate of candidateDates) {
          const checkDate = DateTime.fromISO(candidate).setLocale("en")
          if (!checkDate.invalid) {
            candidateDate = checkDate.toISODate()
            break
          }
        }
      } catch (error) {
        console.log(error)
      }
      return candidateDate
    }

    if (fileLocationTouched && !fileLocationHasError) {
      const date = getDate()
      if (date) {
        setErrorMsg(null)
        setManualInput(false)
        form.setFieldValue(props.fieldname, date)
      } else {
        setErrorMsg("Error when parsing date. Please input manually.")
        setManualInput(true)
        form.setFieldValue(props.fieldname, "")
      }
      form.setFieldTouched(props.fieldname)
    }
  }, [fileLocation, fileLocationTouched, fileLocationHasError, isMultiple])

  if (manualInput) {
    const handleManualInput = (value) => {
      const regex = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
      if (regex.test(value)) {
        const date = DateTime.fromISO(value)
          .setLocale("en")
          .toJSDate()
        if (!date.invalid) {
          setErrorMsg(null)
          return form.setFieldValue(props.fieldname, value)
        }
      }
      form.setFieldValue(props.fieldname, "")
    }
    return (
      <Field name={props.fieldname}>
        {({ field, meta }) => {
          return (
            <div className={styles.field}>
              <label htmlFor={props.fieldname} className={styles.label}>
                {props.label}
              </label>
              <InputMask
                id={props.fieldname}
                onChange={(e) => handleManualInput(e.value)}
                mask="9999-99-99"
                placeholder="YYYY-MM-DD"
              />
              {meta.touched && meta.error && (
                <div className={styles.error}>{meta.error}</div>
              )}
              {errorMsg && <div className={styles.error}>{errorMsg}</div>}
            </div>
          )
        }}
      </Field>
    )
  }

  return (
    <Field name={props.fieldname}>
      {({ field, meta }) => {
        return (
          <div className={styles.field}>
            <label htmlFor={props.fieldname} className={styles.label}>
              {props.label}
            </label>
            <InputText id={props.fieldname} readOnly value={field.value} />
            {meta.touched && meta.error && (
              <div className={styles.error}>{meta.error}</div>
            )}
            {errorMsg && <div className={styles.error}>{errorMsg}</div>}
          </div>
        )
      }}
    </Field>
  )
}
