import React, { useContext, useState } from "react"
import styles from "../../../styles/Workbench.Analyst.module.css"

import { UserContext } from "../../../context/userContext"

import CINR from "../../DataRoom/components/CINR"
import PNRM from "../../DataRoom/components/PNRM"

import PRM from "../../DataRoom/components/PRM"
import CICNC from "../../DataRoom/components/CICNC"

// PR
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"

// comps
import ProjectReportsViews from "../../DataRoom/components/ProjectReportsViews"

export default function Workbench() {
  const userCtx = useContext(UserContext)

  const [videoSource, setVideoSource] = useState(
    "https://www.w3schools.com/html/movie.mp4"
  )

  const handleResourceClick = (resourceId) => {
    const resourceSources = {
      resource_1: "https://www.w3schools.com/html/movie.mp4",
      resource_2: "https://www.w3schools.com/html/mov_bbb.mp4",
      resource_3: "https://www.w3schools.com/html/movie.mp4",
    }
    setVideoSource(resourceSources[resourceId] || videoSource)
  }

  // temp for 'ths_sandbox' organization
  const userOrg = userCtx?.organization_alias
  const handleHideText = () => {
    if (userOrg === "ths_sandbox") {
      return "hidden"
    }
    return ""
  }

  return (
    <div className={styles.workbenchAnalyst}>
      <h1>Workbench</h1>

      <div className={styles.wrapper}>
        <div>
          <div className={`${styles.filterGroup} ${styles.yellow}`}>
            <h3>
              <span className="material-icons">hub</span>Network Selection
            </h3>
            <CICNC />
          </div>

          <div className={`${styles.filterGroup} ${styles.purple}`}>
            <h3>
              <span className="material-icons">analytics</span>Price Rate
              Modeling
            </h3>
            <PRM />
          </div>
        </div>

        <div>
          <h2>
            <span className="material-icons">app_registration</span>
            Views and Downloads
          </h2>
          <ProjectReportsViews />

          <h2>
            <span className="material-icons">contact_support</span>Data Coverage
            Reports and Guides
          </h2>

          <div className="my-4">
            <h4>
              Clinical Categories and Episode Names for Commercial Insurance
              Carrier Billing Codes
            </h4>
            <p className={handleHideText()}>
              Loaded in this tool for modeling is a basket of top billing codes
              representing steady state utilization in a commercial population
              derived from MMA Region claims, MarketScan Commercial Claims and
              Encounters Database and the Healthcare Cost and Utilization
              Project.
            </p>
            <p>
              Download the Price Transparency Tool billing code dataset applied
              for all analytic plots and data reporting. All billing codes have
              been mapped to clinical categories and episodes for applied market
              insights as detailed in the available User Guide.
            </p>

            <ul>
              <li className={handleHideText()}>
                MMA Price Transparency Tool User Guide (version 1.0)
              </li>
              <li className={handleHideText()}>
                MMA Price Transparency Tool Queries for Actuaries (version 1.0)
              </li>
              <li>
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/ths-data-platform-346520.appspot.com/o/resources%2FClinical.Categories.and.Billing.Codes_MMA_20240719.xlsx?alt=media&token=a4ca512f-3101-4da6-9d49-7e1616fce44b"
                  alt="Clinical Categories and Billing Codes"
                  title="Clinical Categories and Billing Codes"
                  download={true}
                >
                  Clinical Categories and Billing Codes (version 20240718)
                </a>
              </li>
              <li className={handleHideText()}>Data Processing Methodology</li>
            </ul>
          </div>

          <div className="my-4">
            <h4>
              Commercial Insurance Carriers, Institutional Negotiated Rate Modes
            </h4>
            <p>
              Listing of the most frequent institutional negotiated rate (mode)
              for each billing code within a clinical category episode by
              regional commercial insurance carrier.
            </p>
            <p>
              Data coverage report specific to machine-readable files updated
              April 2024.
            </p>
            <ul>
              <li>
                <a
                  title="North Carolina, Carrier Institutional Rate Modes"
                  alt="North Carolina, Carrier Institutional Rate Modes"
                  href="https://firebasestorage.googleapis.com/v0/b/ths-data-platform-346520.appspot.com/o/resources%2FCarrier.Institutional.Rate.Modes_North.Carolina_20240719.xlsx?alt=media&token=0b461014-281e-4659-97da-a4621f50e8ad"
                  download={true}
                >
                  North Carolina, Carrier Institutional Rate Modes
                </a>
              </li>
              <li>
                <a
                  title="Texas, Carrier Institutional Rate Modes"
                  alt="Texas, Carrier Institutional Rate Modes"
                  href="https://firebasestorage.googleapis.com/v0/b/ths-data-platform-346520.appspot.com/o/resources%2FCarrier.Institutional.Rate.Modes_Texas_20240401.xlsx?alt=media&token=3038be8b-2a08-4bf0-bd40-1ee506d04cb4"
                  download={true}
                >
                  Texas, Carrier Institutional Rate Modes
                </a>
              </li>
            </ul>
          </div>

          <div className="my-4">
            <h4>Price Rate Modeling Utilization Data</h4>
            <p>
              Price Rate Modeling is available for clinical category in
              designated regional areas.{" "}
              <span className={handleHideText()}>
                The modeling applies Transparency in Coverage (TIC) average
                rates for billing codes to derive projected costs for each
                commercial insurance carrier based on reported claim counts by
                MMA.
              </span>
            </p>
            <p className={handleHideText()}>
              Download Price Transparency utilization datasets for regional
              areas.
            </p>
            <ul className={handleHideText()}>
              <li>
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/ths-data-platform-346520.appspot.com/o/resources%2FPrice.Rate.Modeling_Utilization_Raleigh_20240719.xlsx?alt=media&token=cbe9a221-6007-40db-9460-c5d99eb74e12"
                  alt="Raleigh-Durham CBSA, Price Rate Modeling Inpatient and
                  Outpatient Utilization"
                  title="Raleigh-Durham CBSA, Price Rate Modeling Inpatient and
                  Outpatient Utilization"
                  download={true}
                >
                  Raleigh-Durham CBSA, Price Rate Modeling Inpatient and
                  Outpatient Utilization (version 20240718)
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
