import React, { useState, useRef } from "react"
import axios from "axios"
import _ from "lodash"

import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"

// hooks
import useSubscribeAllDocs from "../../hooks/useSubscribeAllDocs"

import cloudFunctionsURL from "../../config/cloudFunctionsURL"
import { DELETE } from "../../config/constants"

// PR
import { Button } from "primereact/button"
import { SplitButton } from "primereact/splitbutton"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Toast } from "primereact/toast"

export default function UserList() {
  const [deleting, setDeleting] = useState(false)
  const hookParams = {
    collection: "organizations",
  }

  const [loading, error, data] = useSubscribeAllDocs(hookParams)

  const navigate = useNavigate()
  const toast = useRef(null)

  if (error) {
    return (
      <div>
        <h2>Error when fetching.</h2>
      </div>
    )
  }

  const handleDelete = async (organization_alias) => {
    setDeleting(true)
    const url = `${cloudFunctionsURL()}/${DELETE.ORGANIZATION}`
    try {
      const response = await axios.post(url, { organization_alias })
      if (response.data.status === "success") {
        toast.current.show({
          severity: "success",
          summary: "Organization Deleted",
          detail: "The organization was successfully deleted.",
          life: 3000,
        })
        setDeleting(false)
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error Deleting Organization",
        detail: "An error occurred while deleting the organization.",
        life: 3000,
      })
      setDeleting(false)
    }
  }

  const handleData = _.map(data, (row) => {
    const handleActions = (row) => {
      const { id, organization_alias } = row
      const items = [
        {
          label: "Delete",
          icon: "pi pi-times",
          command: () => handleDelete(organization_alias),
        },
      ]
      return (
        <SplitButton
          disabled={deleting}
          label="Edit"
          onClick={() => navigate(`/organization/edit/${id}`)}
          model={items}
        />
      )
    }

    const handleStatus = () => {
      const { organization_status: status } = row
      switch (status) {
        case "ACTIVE":
          return (
            <div style={{ color: "var(--green-600)" }}>
              <i className="pi pi-circle-fill" style={{ marginRight: 10 }} />
              Active
            </div>
          )
        case "INACTIVE":
          return (
            <div style={{ color: "var(--red-400)" }}>
              <i className="pi pi-circle" style={{ marginRight: 10 }} />
              Inactive
            </div>
          )
      }
    }

    const modDate = row.timestamp_updated
      ? row.timestamp_updated.toDate()
      : null

    return {
      ...row,
      updated: DateTime.fromJSDate(modDate).toFormat("F"),
      organization_status: handleStatus(),
      actions: handleActions(row),
    }
  })

  return (
    <div className="container">
      <h1>Organizations</h1>

      <Button
        onClick={() => navigate("/organization/add")}
        style={{ marginBottom: 15 }}
      >
        Add Organization
      </Button>

      <DataTable value={handleData} responsiveLayout="scroll" loading={loading}>
        <Column
          field="organization_name"
          header="Organization Name"
          filter
          sortable
        />
        <Column field="organization_status" header="Status" sortable />
        <Column field="organization_alias" header="Alias" sortable />
        <Column field="updated" header="Last Update" />
        <Column field="actions" header="Actions" />
      </DataTable>
      <Toast ref={toast} />
    </div>
  )
}
