import React, { useState, useEffect, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import _ from "lodash"

import { SET, GET } from "../../../config/constants"
import cloudFunctionsURL from "../../../config/cloudFunctionsURL"

import { SearchCtx } from "../context/searchCtx"

// Loaders
import { LoaderProgressBar } from "../../../components/Loaders"

// Data Grid
import ReactDataGrid from "@inovua/reactdatagrid-enterprise"

// Filters
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter"
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter"

// PR
import { Button } from "primereact/button"

import BackButton from "../../../components/BackButton"

const URL = `${cloudFunctionsURL()}/${GET.GET_PLAN_NAMES_BY_LOCATION}`

export default function Screen3() {
  const navigate = useNavigate()
  const searchCtx = useContext(SearchCtx)
  const { mrfOrder } = useParams()
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [defaultFilterValue, setDefaultFilterValue] = useState([])
  const [selected, setSelected] = useState({})
  const { carrier, planList, locationList, dataOrderId, index } = searchCtx

  useEffect(() => {
    async function fetchData() {
      const queryParams = {
        mrf_order: mrfOrder,
        location_list: _.map(
          locationList,
          ({ plan_location }) => plan_location
        ),
        plan_list: _.map(planList, ({ plan_name }) => plan_name),
        carrier_id: carrier,
      }
      const response = await axios.post(URL, queryParams)
      const handleColumns = _.map(response.data.columns, (column) => {
        if (!_.isEmpty(column.filterEditor)) {
          switch (column.filterEditor) {
            case "SelectFilter":
              column.filterEditor = SelectFilter
              return column
            case "NumberFilter":
              column.filterEditor = NumberFilter
              return column
            case "DateFilter":
              column.filterEditor = "" // not in use.
              return column
            default:
              return column
          }
        }
        return column
      })
      setData(response.data.data)
      const orderAlias = {
        name: "mrf_order_alias",
        header: "MRF Order Alias",
        defaultFlex: 1,
        minWidth: 400,
        rendersInlineEditor: true,
        render: ({ value }, { cellProps }) => {
          let v = cellProps.editProps.inEdit ? cellProps.editProps.value : value
          return (
            <input
              type="text"
              style={{
                height: 30,
                width: "100%",
              }}
              value={v}
              onBlur={() => {
                cellProps.editProps.onComplete()
              }}
              onChange={cellProps.editProps.onChange}
              onFocus={() => cellProps.editProps.startEdit()}
            />
          )
        },
      }
      setColumns([...handleColumns, orderAlias])
      setDefaultFilterValue(response.data.defaultFilterValue)
      setLoading(false)
    }
    fetchData()
    !_.isEmpty(locationList) && fetchData()
  }, [])

  if (loading) return <LoaderProgressBar />

  const onEditComplete = ({ value, columnId, rowIndex }) => {
    const newData = [...data]
    newData[rowIndex][columnId] = value
    setData(newData)
  }

  const handleNext = async () => {
    setSubmitting(true)
    const URL_MRFS_TO_ORDER = `${cloudFunctionsURL()}/${SET.ADD_MRFS_TO_ORDER}`
    const params = {
      dataOrderId,
      fileList: Object.values(selected),
      mrfIndexOrder: index,
    }
    await axios.post(URL_MRFS_TO_ORDER, params)
    setSubmitting(false)
    return navigate(`/search-index/summary/${dataOrderId}`)
  }

  const renderRowDetails = ({ data }) => {
    return (
      <div style={{ padding: 20 }}>
        <h3>Row details:</h3>
        <table>
          <tbody>
            {Object.keys(data).map((name, i) => {
              return (
                <tr key={i}>
                  <td>{name}</td>
                  <td>{data[name]}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div>
      <h1>Add In-Network files to Data Order</h1>
      <BackButton />
      {!_.isEmpty(columns) && (
        <ReactDataGrid
          licenseKey={process.env.REACT_APP_RDG}
          idProperty="id"
          selected={selected}
          checkboxColumn
          pagination
          defaultFilterValue={defaultFilterValue}
          onSelectionChange={({ selected }) => setSelected(selected)}
          columns={columns}
          style={{ minHeight: "60vh", marginTop: 15 }}
          defaultSelected={false}
          dataSource={data}
          enableColumnAutosize
          rowExpandHeight={300}
          renderRowDetails={renderRowDetails}
          onEditComplete={onEditComplete}
          editable={true}
        />
      )}

      <Button
        label="Save to Data Order"
        onClick={() => handleNext()}
        style={{ marginTop: 10 }}
        disabled={submitting}
      />
    </div>
  )
}
