import React, { useContext, useState, useEffect } from "react"
import axios from "axios"
import PropTypes from "prop-types"

// PR
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { MultiSelect } from "primereact/multiselect"

// Ctx
import { UserContext } from "../../../context/userContext"

// DataRoom URL
import { dataRoomURL } from "../../../config/cloudFunctionsURL"
import { LoaderMedium } from "../../../components/Loaders"

// Comps
import SaveReport from "./SaveReport"

function FilterBuilder(props) {
  const { carriedFieldValues = {}, handleGenerate } = props

  const [initReady, setInitReady] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [error, setError] = useState("")

  const [filterList, setFilterList] = useState([])

  const [selectedValues, setSelectedValues] = useState({})
  const [options, setOptions] = useState({})
  const [optionsLoading, setOptionsLoading] = useState({})

  const [fieldOrdering, setFieldOrdering] = useState([])
  const [fieldIndex, setFieldIndex] = useState(0)

  const userCtx = useContext(UserContext)

  const baseUrl = dataRoomURL()

  function handleFieldIndexChange(fieldId) {
    const fieldIndex = fieldOrdering.indexOf(fieldId)
    setFieldIndex(fieldIndex)
  }

  async function handleFetchOptions(filterId) {
    const filter = filterList.find(({ id }) => id === filterId)
    console.log("🏎️ Fetching option for:", filterId)
    setOptionsLoading((prev) => ({
      ...prev,
      [filterId]: true,
    }))
    try {
      let params = {}
      const reqParams = filter.fetch_options.request_params || []
      for (const param of reqParams) {
        if (selectedValues[param.id]) {
          params[param.id] = selectedValues[param.id]
        }
      }
      const response = await axios.post(
        `${baseUrl}/${filter.fetch_options.endpoint}`,
        params
      )
      const options = response.data?.options
      setOptions((prevOptions) => ({
        ...prevOptions,
        [filterId]: options,
      }))
      const defaultValues = response.data?.defaultValues
        ? response.data?.defaultValues[filterId]
        : null
      if (defaultValues && !selectedValues[filterId]) {
        console.log(`Default values for (${filterId}): ${defaultValues}.`)
        setSelectedValues((prevValues) => ({
          ...prevValues,
          [filterId]: defaultValues,
        }))
      }
      setOptionsLoading((prevLoading) => ({
        ...prevLoading,
        [filterId]: false,
      }))
      const newFieldIndex = fieldOrdering.indexOf(filterId)
      if (newFieldIndex > fieldIndex) {
        console.log(newFieldIndex, " <=== Set new fieldIndex for: ", filterId)
        setFieldIndex(newFieldIndex)
      }
    } catch (err) {
      console.error("Error fetching options:", err)
      setError("Error fetching options")
      return []
    }
  }

  function handleResetFields(fieldId) {
    const fieldIndex = fieldOrdering.indexOf(fieldId)
    const fieldsBelow = fieldOrdering.slice(fieldIndex + 1)
    console.log("💣 Resetting fields below:", fieldsBelow)
    fieldsBelow.forEach((field) => {
      setSelectedValues((prevValues) => ({
        ...prevValues,
        [field]: null,
      }))
      setOptions((prevOptions) => ({
        ...prevOptions,
        [field]: null,
      }))
    })
  }

  function handleChange(fieldId, value) {
    console.log(fieldId, value, "here")
    handleFieldIndexChange(fieldId)
    handleResetFields(fieldId)
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [fieldId]: value,
    }))
  }

  // onMount: Fetch def + state initiation and cleanup for unmount.
  useEffect(() => {
    async function fetchData() {
      const postBody = {
        user_role: userCtx.user_role.toString(), // temp until JWT
        data_region: userCtx.data_region.toString(), // temp until JWT
        ...props.carriedFieldValues,
      }
      const { screen, filterGroupId } = props
      try {
        const response = await axios.post(
          `${baseUrl}/api/filters/definitions/${filterGroupId}/${screen}`,
          postBody
        )
        const filterListDef = response.data?.filter_list || []
        setFilterList(filterListDef)
        initState(filterListDef)
      } catch (error) {
        console.error(
          `Error fetching Filter def for ${filterGroupId} in screen ${screen}: `,
          error
        )
      }
    }

    function initState(filterListDef) {
      const initialSelectedValues = filterListDef.reduce((acc, filter) => {
        return {
          [filter.id]: null,
          ...acc, // carriedFieldValues should remain.
        }
      }, carriedFieldValues)

      const initialOptionsLoading = filterListDef.reduce((acc, filter) => {
        return {
          ...acc,
          [filter.id]: false,
        }
      }, {})

      const initialFieldsIndex = filterListDef.map((filter) => filter.id)

      setSelectedValues(initialSelectedValues)
      setOptionsLoading(initialOptionsLoading)
      setFieldOrdering(initialFieldsIndex)

      setInitReady(true)
    }

    fetchData()

    // Cleanup
    return () => {
      setSelectedValues({})
      setOptions({})
      setOptionsLoading({})
      setFieldOrdering([])
      setFieldIndex(0)
      setInitReady(false)
      setFirstLoad(true)
      setError("")
    }
  }, [])

  // onMount: Fetch Options for Fields without any 'Request Params'.
  useEffect(() => {
    if (!initReady) return
    filterList.forEach((filter) => {
      if (filter.fetch_options) {
        const noReqParams = filter.fetch_options.request_params.length === 0
        if (noReqParams) {
          handleFetchOptions(filter.id)
        }
      }
    })
  }, [initReady])

  // onMount: Fetch Options for first Field.
  useEffect(() => {
    if (!initReady) return
    if (filterList.length === 0) return

    const filter = filterList[0] || {}

    const reqParams = filter?.fetch_options?.request_params || []

    const allReqParamsFilled = reqParams.every((param) => {
      const currentParamValue = selectedValues[param.id] || null
      return currentParamValue !== null
    })

    if (allReqParamsFilled) {
      handleFetchOptions(filter.id)
    }
  }, [initReady, filterList, selectedValues])

  // Trigger Fetch Options for Fields Meeting 'Request Params'
  useEffect(() => {
    filterList.forEach((filter) => {
      const reqParams = filter.fetch_options.request_params || []
      const cadidateIndex = fieldOrdering.indexOf(filter.id)
      if (cadidateIndex > fieldIndex) {
        const allReqParamsFilled = reqParams.every((param) => {
          const currentParamValue = selectedValues[param.id] || null

          const isEmptyArr =
            Array.isArray(currentParamValue) && currentParamValue.length === 0

          if (isEmptyArr) return false

          return currentParamValue !== null
        })
        if (allReqParamsFilled) handleFetchOptions(filter.id)
      }
    })
  }, [selectedValues, fieldIndex])

  // Check if firstLoad and lastField to trigger 'handleGenerate'
  useEffect(() => {
    if (props.screen !== 2) return
    const isLastField = fieldIndex === fieldOrdering.length - 1
    if (isLastField && firstLoad) {
      handleGenerate(selectedValues)
      setFirstLoad(false)
    }
  }, [fieldIndex])

  if (!initReady) return <LoaderMedium />
  if (error) return <div className="p5 m5">Error: {error}</div>

  // Group filters by field_group
  const groupedFilters = filterList.reduce((acc, filter) => {
    if (filter.hidden) return acc
    const group = filter.field_group || "default"
    if (!acc[group]) acc[group] = []
    acc[group].push(filter)
    return acc
  }, {})

  // Check if all required fields are filled
  function isValidate() {
    const isLoading = Object.values(optionsLoading).some((loading) => loading)
    if (isLoading) return false
    const requieredFields = filterList.map((filter) => filter.id)
    return requieredFields.every(
      (field) =>
        selectedValues[field] !== null &&
        selectedValues[field] !== undefined &&
        selectedValues[field].length !== 0
    )
  }

  return (
    <div className="bg-white p-3 border-round-sm">
      {Object.entries(groupedFilters).map(([group, filters]) => (
        <div
          key={group}
          className={`filter-group surface-100 border-round-sm p-3 ${
            group !== "-1" ? `filter-group-${group}` : ""
          } mb-4`}
        >
          {filters.map((filter) => {
            const FilterComponent =
              filter.type === "dropdown" ? Dropdown : MultiSelect
            return (
              <div key={filter.id} className="filter-item mb-3">
                <label className="text-sm">{filter.name}</label>
                <div className="mt-1">
                  <FilterComponent
                    value={selectedValues[filter.id]}
                    options={options[filter.id] || []}
                    onChange={(e) => handleChange(filter.id, e.value)}
                    loading={optionsLoading[filter.id]}
                    placeholder="Please select"
                    filter
                  />
                </div>
              </div>
            )
          })}
        </div>
      ))}

      <Button
        label="Visualize"
        onClick={() => handleGenerate(selectedValues)}
        className={
          isValidate()
            ? "w-full mb-2 btn-secondary"
            : "w-full mb-2 btn-secondary grey"
        }
        disabled={!isValidate()}
      />
      {props.screen === 2 && (
        <SaveReport selectedValues={selectedValues} isValidate={isValidate} />
      )}
    </div>
  )
}

FilterBuilder.propTypes = {
  handleGenerate: PropTypes.func.isRequired,
  carriedFieldValues: PropTypes.object,
  filterGroupId: PropTypes.string.isRequired,
  screen: PropTypes.number.isRequired,
}

export default FilterBuilder
