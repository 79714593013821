import React, { useState, useContext, useMemo } from "react"
import _ from "lodash"
import axios from "axios"

// Ctx
import { UserContext } from "../../../../context/userContext"

// Comps
import FilterBuilder from "../../components/FieldBuilder"
import UserHelpText from "../../components/UserHelpText"
import DataTableStats from "./components/DataTableStats"
import DataTableRank from "./components/DataTableRank"

// DataRoom URL
import { dataRoomURL } from "../../../../config/cloudFunctionsURL"

// Loaders
import { LoaderProgressBar } from "../../../../components/Loaders"

// Styles
import styles from "../../../../styles/DataRoom.module.css"

// HC
import Map from "./highcharts/Map"

const baseUrl = dataRoomURL()

export default function ProviderNetworkCodeMap() {
  const [fetching, setFetching] = useState(false)
  const userCtx = useContext(UserContext)

  const [payload, setPayload] = useState({})
  const [rankdata, setRankdata] = useState([])

  const [geomeandata, setGeomeandata] = useState([]) // TODO: check if this is needed

  // Deprecated
  const [maxOpBillingCodeTypeLabel, setMaxOpBillingCodeTypeLabel] = useState(8) // TODO: check if this is needed

  // Reports: *******TODO: Remove*******
  const [reportId, setReportId] = useState("")
  const [projectName, setProjectName] = useState("")
  const [reportName, setReportName] = useState("")

  // Params from URL
  const queryString = window.location.search

  // URL Param Values + Visualization
  const carriedFieldValues = useMemo(() => {
    const urlParams = new URLSearchParams(queryString)
    return {
      geomap_view: urlParams.get("geomap_view") ?? "",
      npi_practice_state: urlParams.get("npi_practice_state") ?? "",
      view_rates_by: urlParams.get("view_rates_by") ?? "",
      map_layer: urlParams.get("map_layer") ?? "",
      billing_code_category:
        _.split(urlParams.get("billing_code_category"), ",") ?? "",
      reportId: urlParams.get("report_id") ?? null,
      visualization: "geomap",
    }
  }, [queryString])

  // TODO: Check if this is needed
  const getStoredValues = async () => {
    const { report_id } = carriedFieldValues
    setReportId(report_id)
    const url = `${baseUrl}/api/reports/get-full-report`
    const response = await axios.post(url, {
      report_id,
    })
    const reportName = response.data.report_name || ""
    const projectName = response.data.project_name || ""
    setProjectName(projectName)
    setReportName(reportName)
    const values = response.data.values || {}
    return values
  }

  const handleGenerate = async (selectedValues = {}) => {
    setPayload({})
    setFetching(true)
    const postBody = {
      ...selectedValues,
      user_role: userCtx.user_role.toString(), // temp until JWT
      data_region: userCtx.data_region.toString(), // temp until JWT
    }
    try {
      const fetchUrl = `${baseUrl}/api/dashboard/provider-network-regional-map`
      const statsUrl = `${baseUrl}/api/dashboard/provider-network-regional-map-detail`

      const [payload, stats] = await Promise.all([
        axios.post(fetchUrl, postBody),
        axios.post(statsUrl, postBody),
      ])

      setPayload(payload.data)
      setGeomeandata(stats.data.data)
      setRankdata(stats.data.data_rank)

      setFetching(false)
    } catch (error) {
      console.log("Error fetching data", error)
      setFetching(false)
    }
  }

  const handleWeightProp = () => {
    switch (carriedFieldValues.geomap_view) {
      case "Provider Network Regional Map":
        return "number_providers"
      case "Negotiated Rate Averages Regional Map":
        return "mean"
      default:
        return ""
    }
  }

  const handleMapTitle = () => {
    const bundleList =
      carriedFieldValues.billing_code_category.join(", ") || null
    switch (carriedFieldValues.geomap_view) {
      case "Provider Network Regional Map":
        return `Facility Provider Network and Carrier Ranking Map, ${bundleList}`
      case "Negotiated Rate Averages Regional Map":
        return `Categorical Range and Average for Negotiated Rates, ${bundleList}`
      default:
        return ""
    }
  }

  const handleMapSubTitle = () => {
    switch (carriedFieldValues.map_layer) {
      case "county_name":
        return `Visualization by Counties`
      case "cbsa_msa_name":
        return `Visualization by CBSA-MSA`
      default:
        return ""
    }
  }

  const handleGeoNameLabel = () => {
    switch (carriedFieldValues.map_layer) {
      case "county_name":
        return "County"
      case "cbsa_msa_name":
        return "CBSA-MSA"
      default:
        return null
    }
  }

  const handleChartRender = () => {
    if (fetching) return <LoaderProgressBar />
    if (_.isEmpty(payload)) return <UserHelpText />

    const showMap = !_.isEmpty(payload) && !_.isEmpty(geomeandata)
    if (!showMap) return <div>No results found.</div>

    const isNegocitated =
      carriedFieldValues.geomap_view === "Negotiated Rate Averages Regional Map"

    const isProvider =
      carriedFieldValues.geomap_view === "Provider Network Regional Map"

    return (
      <div className={styles.map}>
        <Map
          region={carriedFieldValues.npi_practice_state}
          data={payload.data}
          min={payload.min}
          max={payload.max}
          geo_boundary={carriedFieldValues.map_layer}
          weight_key={handleWeightProp()}
          title={handleMapTitle()}
          subTitle={handleMapSubTitle()}
          isNegocitated={isNegocitated}
          isProvider={isProvider}
        />
        <div className="grid flex-nowrap gap-3 mt-5">
          <DataTableRank
            data={rankdata}
            isNegocitated={isNegocitated}
            isProvider={isProvider}
            mapLayer={carriedFieldValues.map_layer}
          />
          <DataTableStats
            data={geomeandata}
            geoNameLabel={handleGeoNameLabel}
            isNegocitated={isNegocitated}
            isProvider={isProvider}
          />
        </div>
      </div>
    )
  }

  const handleH1Title = () => {
    switch (carriedFieldValues.geomap_view) {
      case "Provider Network Regional Map":
        return "Provider Network and Carrier Clinical Category Ranking Map"
      case "Negotiated Rate Averages Regional Map":
        return "Negotiated Rate Averages Regional Map"
      default:
        return ""
    }
  }

  // temp for 'ths_sandbox' organization
  const userOrg = userCtx?.organization_alias
  const handleHideText = () => {
    if (userOrg === "ths_sandbox") {
      return "hidden"
    }
    return ""
  }

  const handleDescription = () => {
    switch (carriedFieldValues.geomap_view) {
      case "Provider Network Regional Map":
        return (
          <div>
            <p>
              The Provider Network Regional Map displays a series of heath care
              facility provider system and type characteristics as reported
              within commercial insurance carrier network directories and
              references.
            </p>
            <p>
              The map can be viewed by County or Core-Based Statistical Areas
              (CBSA) regions for provider density by selected clinical
              categories. Provider density (number of providers in the network)
              is indicated by map color scale with greater density showing in a
              darker color shade.
            </p>
            <p>
              For each selected Clinical Category, geographic regions where
              commercial insurance carriers have negotiated rates with providers
              are highlighted. Select a County or CBSA region to view provider
              network details and competitive ranking of carrier plans based on
              market rate average positions for category billing codes. Loaded
              in this tool for modeling is a basket of top billing codes
              representing steady state utilization in a commercial population
              derived from{" "}
              <span className={handleHideText()}>MMA Region claims, </span>
              MarketScan Commercial Claims and Encounters Database and the
              Healthcare Cost and Utilization Project.
            </p>
          </div>
        )
      case "Negotiated Rate Averages Regional Map":
        return (
          <p>
            The Negotiated Rate Averages Regional Map displays a series of
            negotiated rate averages by selected clinical categories and
            procedure types. The map can be viewed by County or Core-Based
            Statistical Areas (CBSA) boundaries for provider density by selected
            clinical categories.
          </p>
        )
      default:
        return
    }
  }

  return (
    <div className={`${styles.pageLayout} data-room`}>
      <h1>{handleH1Title()}</h1>
      {handleDescription()}
      <div className={styles.wrapper}>
        <div className="filters">
          <FilterBuilder
            handleGenerate={handleGenerate}
            carriedFieldValues={carriedFieldValues}
            filterGroupId={"provider-network-map"}
            screen={2}
          />
        </div>
        {handleChartRender()}
      </div>
    </div>
  )
}
