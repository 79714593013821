import { useState } from "react"
import axios from "axios"

export default function useGetHCData() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [error, setError] = useState(null)

  async function fetchData(endpoint) {
    setLoading(true)
    try {
      const baseUrl = "http://localhost:7000/api/dashboard"
      const response = await axios.post(`${baseUrl}/${endpoint}`)
      setData(response.data.dashboard)
      setLoading(false)
    } catch (error) {
      setError(error)
    }
  }

  return [loading, error, data, fetchData]
}
