import React, { useEffect } from "react"
import { useFormikContext } from "formik"

import _ from "lodash"
import * as FieldTypes from "./fieldTypes"
import TypeCustom from "./fieldTypes/TypeCustom"

function TypeConditional(props) {
  const { field_conditional, fieldname } = props.field
  const { values, setFieldValue } = useFormikContext()

  const getConditionalFieldKey = () => {
    const keyList = Object.keys(field_conditional)
    return keyList[0]
  }

  const conditionalFieldKey = getConditionalFieldKey()
  const conditionalFieldValue = field_conditional[conditionalFieldKey]

  let displayField

  if (props.field.type === "dropdown") {
    displayField = values[conditionalFieldKey] === conditionalFieldValue
  } else {
    displayField = _.reduce(
      conditionalFieldValue,
      (acc, option) => {
        console.log("option", option)
        return acc || _.includes(values[conditionalFieldKey], option)
      },
      false
    )
  }

  useEffect(() => {
    if (!displayField) {
      setFieldValue(fieldname, "")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayField])
  if (!displayField) return null
  return props.children
}

export default function FieldBuilder(props) {
  const { fields, dataOrderId, editMode = false } = props

  const fieldsOrdered = _.orderBy(fields, "order")
  return _.map(fieldsOrdered, (field) => {
    const { type, fieldname, options, label } = field
    const description = field.description || ""
    const fieldConditional = field.field_conditional || null
    const newProps = {
      fieldname,
      label,
      description,
      options,
      dataOrderId,
      editMode,
    }
    switch (type) {
      case "custom":
        if (!_.isEmpty(fieldConditional)) {
          return (
            <TypeConditional field={field} key={fieldname}>
              <TypeCustom {...newProps} key={fieldname} />
            </TypeConditional>
          )
        }
        return <TypeCustom {...newProps} key={fieldname} />
      case "text":
      case "url":
      case "gs":
        if (!_.isEmpty(fieldConditional)) {
          return (
            <TypeConditional field={field} key={fieldname}>
              <FieldTypes.TypeText {...newProps} />
            </TypeConditional>
          )
        }
        return <FieldTypes.TypeText {...newProps} key={fieldname} />
      case "textarea":
        return <FieldTypes.TypeTextarea {...newProps} key={fieldname} />
      case "editor":
        return <FieldTypes.TypeEditor {...newProps} key={fieldname} />
      case "email":
        return <FieldTypes.TypeEmail {...newProps} key={fieldname} />
      case "multi-selector":
        return <FieldTypes.TypeMultiSelect {...newProps} key={fieldname} />
      case "dropdown": {
        if (!_.isEmpty(fieldConditional)) {
          return (
            <TypeConditional field={field} key={fieldname}>
              <FieldTypes.TypeDropdown {...newProps} />
            </TypeConditional>
          )
        }
        return <FieldTypes.TypeDropdown {...newProps} key={fieldname} />
      }
      case "password":
        return <FieldTypes.TypePassword {...newProps} key={fieldname} />
      case "radiobutton":
        return <FieldTypes.TypeRadioButton {...newProps} key={fieldname} />
      case "no-special-characters":
        if (!_.isEmpty(fieldConditional)) {
          return (
            <TypeConditional field={field} key={fieldname}>
              <FieldTypes.TypeNoSpecialCharacters
                {...newProps}
                key={fieldname}
              />
            </TypeConditional>
          )
        }
        return (
          <FieldTypes.TypeNoSpecialCharacters {...newProps} key={fieldname} />
        )
      default:
        return null
    }
  })
}
