import React from "react"
import { useNavigate } from "react-router-dom"

// Comps
import FilterBuilder from "./FieldBuilder"

// helper
import objectToUrlParams from "../../../helpers/objectToUrlParams"

import styles from "../../../styles/DataRoom.module.css"

export default function ProviderNetworkMapScreenOne() {
  const navigate = useNavigate()
  const handleGenerate = (selectedValues) => {
    const paramsString = objectToUrlParams(selectedValues)
    return navigate(
      `/data-room/provider-network-map/provider-network-regional-map?${paramsString}`
    )
  }
  return (
    <div className={styles.initFilters}>
      <FilterBuilder
        handleGenerate={handleGenerate}
        filterGroupId={"provider-network-map"}
        screen={1}
      />
    </div>
  )
}
