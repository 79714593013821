import React, { useState, useRef } from "react"
import axios from "axios"
import _ from "lodash"

import { Formik, Form } from "formik"
import { useNavigate } from "react-router-dom"

import schemaBuilder from "../../components/schemaBuilder"

// constants
import { ENTITY, DEF, ADD, EDIT } from "../../config/constants"

// hooks
import useGetFormDef from "../../hooks/useGetFormDef"

// functions URL
import cloudFunctionsURL from "../../config/cloudFunctionsURL"

// comps
import FieldBuilder from "../../components/FieldBuilder"
import { LoaderSkeleton } from "../../components/Loaders"

// PR
import { Button } from "primereact/button"
import { Messages } from "primereact/messages"

export default function FormWrapper({ type, docValues }) {
  const navigate = useNavigate()

  const msg = useRef(null)
  const [submitting, setSubmitting] = useState(false)

  const params = {
    formId: ENTITY.PROJECT,
    endpoint: DEF.FORM,
  }

  const [error, loading, def] = useGetFormDef(params)

  if (error) return <div>Err...</div>
  if (loading) return <LoaderSkeleton count={10} />

  const [initialValues, validationSchema] = schemaBuilder(def.fields)

  const handleSubmit = async (values) => {
    setSubmitting(true)
    const endpoint = () => {
      switch (type) {
        case "add":
          return ADD.PROJECT
        case "edit":
          return EDIT.PROJECT
      }
    }
    const url = `${cloudFunctionsURL()}/${endpoint()}`
    try {
      const response = await axios.post(url, values)
      setSubmitting(false)
      if (response.data.status === "success") {
        return navigate("/data-order/list")
      }
    } catch (error) {
      msg.current.show([
        {
          severity: "error",
          summary: "Error:",
          detail: error.response.data.msg,
          sticky: true,
          closable: false,
        },
      ])
      setSubmitting(false)
    }
  }

  const handleInitialValues = () => {
    switch (type) {
      case "add":
        return initialValues
      case "edit":
        return {
          ...initialValues,
          ...docValues,
        }
    }
  }

  return (
    <Formik
      initialValues={handleInitialValues()}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form style={{ margin: "15px 30px" }}>
          <div style={{ margin: "10px 0" }}>
            <Messages ref={msg} />
          </div>
          <FieldBuilder fields={def.fields} editMode={type === "edit"} />
          <Button type="submit" disabled={submitting}>
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  )
}
