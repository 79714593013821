import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import axios from "axios"
import _ from "lodash"

import { GET } from "../../../config/constants"
import cloudFunctionsURL from "../../../config/cloudFunctionsURL"

import { SearchCtx } from "../context/searchCtx"

// Loaders
import { LoaderProgressBar } from "../../../components/Loaders"

// comps
import CarrierSelection from "../components/CarrierSelection"

// PR
import { InputText } from "primereact/inputtext"
import { RadioButton } from "primereact/radiobutton"
import { Button } from "primereact/button"
import { DataTable } from "primereact/datatable"
import { ProgressBar } from "primereact/progressbar"
import { Column } from "primereact/column"

// Data Grid
import ReactDataGrid from "@inovua/reactdatagrid-enterprise"

// Filters
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter"
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter"

import "@inovua/reactdatagrid-enterprise/index.css"

export default function Screen1() {
  const navigate = useNavigate()
  const searchCtx = useContext(SearchCtx)
  const { mrfOrder, type, screen } = useParams()
  const [loading, setLoading] = useState(true)
  const [searching, setSearching] = useState(false)

  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])

  const [dataDistTablePlan, setDataDistTablePlan] = useState(null)

  const [defaultFilterValue, setDefaultFilterValue] = useState([])
  const [selected, setSelected] = useState({})
  const [currentIndex, setCurrentIndex] = useState(null)
  const [searchType, setSearchType] = useState("")
  const [searchTerm, setSearchTerm] = useState("")

  const URL_MAIN = `${cloudFunctionsURL()}/${
    GET.SEARCH_CARRIER_PLAN_DIST_BY_MARKET_TYPE
  }`

  const URL_PLAN_DIST_BY_ATT = `${cloudFunctionsURL()}/${
    GET.SEARCH_CARRIER_PLAN_DIST_BY_ATT
  }`

  const queryParams = {
    mrf_order: mrfOrder,
    plan_market_type: "group",
    limit: 25,
  }

  const handleSearch = async () => {
    setSearching(true)
    const response = await axios.post(URL_MAIN, {
      ...queryParams,
      mrf_order: currentIndex || mrfOrder,
    })
    setData(response.data.data)
    setSearching(false)
  }

  useEffect(() => {
    async function fetchData() {
      const responses = await Promise.all([
        axios.post(URL_MAIN, queryParams),
        axios.post(URL_PLAN_DIST_BY_ATT, {
          mrf_order: "pubsub_anthem",
          attribute: "plan_id_type",
        }),
      ])

      const [responseMainTable, responseDistTablePlan] = responses

      console.log(responseDistTablePlan)

      setDataDistTablePlan(responseDistTablePlan.data.data)

      const handleColumns = _.map(responseMainTable.data.columns, (column) => {
        if (!_.isEmpty(column.filterEditor)) {
          switch (column.filterEditor) {
            case "SelectFilter":
              column.filterEditor = SelectFilter
              return column
            case "NumberFilter":
              column.filterEditor = NumberFilter
              return column
            case "DateFilter":
              column.filterEditor = "" // not in use.
              return column
            default:
              return column
          }
        }
        return column
      })
      setData(
        _.map(responseMainTable.data.data, (row) => {
          return {
            ...row,
            plan_percentage: (
              <ProgressBar
                value={row.plan_percentage}
                style={{ height: "3px" }}
                showValue={false}
              />
            ),
          }
        })
      )
      setColumns(handleColumns)
      setDefaultFilterValue(responseMainTable.data.defaultFilterValue)
      setLoading(false)
    }
    fetchData()
  }, [])

  if (loading) return <LoaderProgressBar />

  const handleNext = () => {
    searchCtx.setPlanList(selected)
    const nextScreen = Number(screen) + 1
    return navigate(`/search-index/${type}/${mrfOrder}/${nextScreen}`)
  }

  const documentStyle = getComputedStyle(document.documentElement)

  const chartData = {
    labels: ["A", "B", "C"],
    datasets: [
      {
        data: [300, 50, 100],
        backgroundColor: [
          documentStyle.getPropertyValue("--blue-500"),
          documentStyle.getPropertyValue("--yellow-500"),
          documentStyle.getPropertyValue("--green-500"),
        ],
        hoverBackgroundColor: [
          documentStyle.getPropertyValue("--blue-400"),
          documentStyle.getPropertyValue("--yellow-400"),
          documentStyle.getPropertyValue("--green-400"),
        ],
      },
    ],
  }

  return (
    <div>
      <h1>Query Index by Plan Types and Products</h1>
      <CarrierSelection />
      <div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder="Enter your query"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </span>
      </div>

      <div style={{ display: "flex", gap: 5, marginTop: 10 }}>
        <div>
          <RadioButton
            inputId="plan-id"
            name="plan-id"
            value="plan_id"
            onChange={(e) => setSearchType(e.value)}
            checked={searchType === "plan_id"}
          />
          <label htmlFor="plan-id">Search by Plan Id</label>
        </div>
        <div>
          <RadioButton
            inputId="plan-name"
            name="plan-name"
            value="plan_name"
            onChange={(e) => setSearchType(e.value)}
            checked={searchType === "plan_name"}
          />
          <label htmlFor="plan-name">Search by Plan Name</label>
        </div>
        <div>
          <RadioButton
            inputId="plan-product-description"
            name="plan-product-description"
            value="plan_product_description"
            onChange={(e) => setSearchType(e.value)}
            checked={searchType === "plan_product_description"}
          />
          <label htmlFor="plan-product-description">
            Search by Plan Product Description
          </label>
        </div>
      </div>

      <Button
        label="Search Index"
        onClick={() => handleSearch()}
        style={{ marginTop: 10 }}
        outlined
      />

      <ReactDataGrid
        idProperty="id"
        selected={selected}
        loading={searching}
        checkboxColumn
        pagination
        defaultFilterValue={defaultFilterValue}
        onSelectionChange={({ selected }) => setSelected(selected)}
        columns={columns}
        style={{ minHeight: "40vh", marginTop: 15 }}
        defaultSelected={false}
        dataSource={data}
        enableColumnAutosize
      />

      <br />
      <br />

      <DataTable value={dataDistTablePlan} tableStyle={{ minWidth: "50rem" }}>
        <Column field="plan_id_type" header="Plan Id Type" />
        <Column field="plan_count" header="Plan Count" />
      </DataTable>

      <Button
        label="View Files for Order"
        onClick={() => handleNext()}
        style={{ marginTop: 10 }}
      />
    </div>
  )
}
