import React, { useContext } from "react"
import { UserContext } from "../context/userContext"

import styles from "../styles/Header.module.css"

export default function Header() {
  const userCtx = useContext(UserContext)
  const fullName = userCtx
    ? `${userCtx.name_first} ${userCtx.name_last}`
    : "Guest"
  const roleType = () => {
    switch (userCtx.user_role) {
      case 0:
        return "SysAdmin"
      case 1:
        return "Admin"
      case 10:
        return "Analyst"
      default:
        return "User"
    }
  }
  const currentRole = roleType()
  const orgName = userCtx.organization_name || "No Organization"
  return (
    <header className={`header ${styles.header}`}>
      <div>
        <strong>{currentRole}</strong>: {fullName} | {orgName}
      </div>
      <div>
        {/* <ul>
          <li>
            <span className="material-icons">shopping_cart</span>
          </li>
          <li>
            <span className="material-icons">history</span>
          </li>
          <li>
            <span className="material-icons">hourglass_top</span>
          </li>
        </ul> */}
      </div>
      {/* <div>Help</div> */}
    </header>
  )
}
