import React, { useState } from "react"
import "primeflex/primeflex.css"

import "./styles/hfma.css"

// Layout
import Header from "./layout/Header"
import Insights from "./components/Insights"

// comps
import Filters from "./components/Filters"
import HeatMap from "./components/HeatMap"

export default function HfmaDashboard() {
  const [insight, setInsights] = useState(1)
  return (
    <div className="flex flex-column">
      <Header />
      <div className="hfma-body">
        <div className="hfma-container">
          <div className="filters-section">
            <Filters insight={insight} setInsights={setInsights} />
          </div>
          <div className="insights-section">
            <Insights insight={insight} />
          </div>
          <div className="hc-section">
            <HeatMap />
          </div>
        </div>
      </div>
    </div>
  )
}
