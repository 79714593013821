import React, { useState, useEffect, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import _ from "lodash"

import { GET } from "../../../config/constants"
import cloudFunctionsURL from "../../../config/cloudFunctionsURL"

import { SearchCtx } from "../context/searchCtx"

// Loaders
import { LoaderProgressBar } from "../../../components/Loaders"

// Data Grid
import ReactDataGrid from "@inovua/reactdatagrid-enterprise"

// Filters
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter"
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter"

// PR
import { Button } from "primereact/button"

import BackButton from "../../../components/BackButton"

const URL = `${cloudFunctionsURL()}/${GET.GET_LOCATIONS_BY_TIC}`

export default function Screen2() {
  const navigate = useNavigate()
  const searchCtx = useContext(SearchCtx)
  const { mrfOrder, type, screen } = useParams()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [defaultFilterValue, setDefaultFilterValue] = useState([])
  const [selected, setSelected] = useState({})
  const { planList } = searchCtx

  function handlePlanList() {
    if (!_.isEmpty(planList)) {
      sessionStorage.setItem("planList", JSON.stringify(planList))
      return planList
    }
    const planListFromSession = sessionStorage.getItem("planList")
    return JSON.parse(planListFromSession) || []
  }

  useEffect(() => {
    const currentPlantList = handlePlanList()
    async function fetchData() {
      const queryParams = {
        mrf_order: mrfOrder,
        tic_uuid: _.map(currentPlantList, (row) => row.tic_uuid),
      }
      const response = await axios.post(URL, queryParams)
      const handleColumns = _.map(response.data.columns, (column) => {
        if (!_.isEmpty(column.filterEditor)) {
          switch (column.filterEditor) {
            case "SelectFilter":
              column.filterEditor = SelectFilter
              return column
            case "NumberFilter":
              column.filterEditor = NumberFilter
              return column
            case "DateFilter":
              column.filterEditor = "" // not in use.
              return column
            default:
              return column
          }
        }
        return column
      })
      setColumns(handleColumns)
      setData(response.data.data)
      setDefaultFilterValue(response.data.defaultFilterValue)
      setLoading(false)
    }
    !_.isEmpty(currentPlantList) && fetchData()
  }, [planList])

  if (loading) return <LoaderProgressBar />

  const handleNext = () => {
    searchCtx.setLocationList(selected)
    const nextScreen = Number(screen) + 1
    return navigate(`/search-index/${type}/${mrfOrder}/${nextScreen}`)
  }

  const renderRowDetails = ({ data }) => {
    return (
      <div style={{ padding: 20 }}>
        <h3>Row details:</h3>
        <table>
          <tbody>
            {Object.keys(data).map((name, i) => {
              return (
                <tr key={i}>
                  <td>{name}</td>
                  <td>{data[name]}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div>
      <h1>Preprocessing In-Network Files </h1>
      <BackButton />
      {!_.isEmpty(columns) && (
        <ReactDataGrid
          licenseKey={process.env.REACT_APP_RDG}
          idProperty="id"
          selected={selected}
          columns={columns}
          loading={loading}
          checkboxColumn
          pagination
          defaultFilterValue={defaultFilterValue}
          onSelectionChange={({ selected }) => setSelected(selected)}
          style={{ minHeight: "60vh", marginTop: 15 }}
          defaultSelected={false}
          dataSource={data}
          enableColumnAutosize
          rowExpandHeight={300}
          renderRowDetails={renderRowDetails}
        />
      )}

      <Button
        label="Add Files to Order"
        onClick={() => handleNext()}
        style={{ marginTop: 10 }}
      />
    </div>
  )
}
