import _ from "lodash"

const trimURL = (url) => {
  try {
    const urlObject = new URL(url)
    const urlDomain = `${urlObject.protocol}/${urlObject.hostname}/`
    return _.truncate(urlDomain, {
      length: 40,
      omission: "",
    })
  } catch (error) {
    return url
  }
}

export default trimURL
