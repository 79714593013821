import React, { useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { UserContext } from "../../../context/userContext"
import axios from "axios"

// PR
import { InputText } from "primereact/inputtext"
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import { RadioButton } from "primereact/radiobutton"
import { SplitButton } from "primereact/splitbutton"

// DataRoom URL
import { dataRoomURL } from "../../../config/cloudFunctionsURL"

import { LoaderProgressBar } from "../../../components/Loaders"

// Hooks
import useTriggerDataLayer from "../../../hooks/useTriggerDataLayer"

export default function SaveReport(props) {
  const [loading, setLoading] = useState(false)

  // Params from URL
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  const reportIdFromURL = urlParams.get("report_id") ?? null

  const [reportId, setReportId] = useState(reportIdFromURL)

  const [reportName, setReportName] = useState("")
  const [projectName, setProjectName] = useState("")

  const [triggerDataLayer] = useTriggerDataLayer()

  const userCtx = useContext(UserContext)
  const baseUrl = dataRoomURL()

  const [permission, setPermission] = useState("private")
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    async function getReportData() {
      setLoading(true)
      try {
        const getReportURL = `${baseUrl}/api/reports/get-report-metadata/${reportIdFromURL}?user_role=${userCtx.user_role}&user_id=${userCtx.id}`
        const response = await axios.get(getReportURL)
        const savedReportName = response.data?.report_name || ""
        const savedProjectName = response.data?.project_name || ""
        setReportName(savedReportName)
        setProjectName(savedProjectName)
        setLoading(false)
      } catch (error) {
        console.error(
          `Error fetching report data for reportId: ${reportIdFromURL}`,
          error
        )
        setLoading(false)
      }
    }
    if (reportIdFromURL) getReportData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportIdFromURL])

  const handlePermissionChoice = () => {
    if (userCtx.user_role > 11) {
      return (
        <div className="card flex mt-1">
          <div className="flex flex-wrap gap-3">
            <div className="flex align-items-center">
              <RadioButton
                inputId="private"
                name="private"
                value="private"
                onChange={(e) => setPermission(e.value)}
                checked={permission === "private"}
              />
              <label htmlFor="private" className="ml-2">
                Private
              </label>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="card flex mt-1">
        <div className="flex flex-wrap gap-3">
          <div className="flex align-items-center">
            <RadioButton
              inputId="private"
              name="private"
              value="private"
              onChange={(e) => setPermission(e.value)}
              checked={permission === "private"}
            />
            <label htmlFor="private" className="ml-2">
              Private
            </label>
          </div>

          <div className="flex align-items-center">
            <RadioButton
              inputId="public"
              name="public"
              value="public"
              onChange={(e) => setPermission(e.value)}
              checked={permission === "public"}
            />
            <label htmlFor="public" className="ml-2">
              Public
            </label>
          </div>
        </div>
      </div>
    )
  }

  const handleExportData = async () => {
    const currentPath = window.location.pathname
    const splitPath = currentPath.split("/")
    const visualizationType = splitPath[2] || null
    const postBody = props.selectedValues
    try {
      const response = await axios.post(
        `${baseUrl}/api/reports/export-report/${visualizationType}`,
        postBody,
        {
          responseType: "blob",
        }
      )
      const blob = new Blob([response.data], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      const timestamp = new Date().getTime()
      a.download = `report_${timestamp}.csv`
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      const dataLayerBody = postBody
      triggerDataLayer("report_export_download", dataLayerBody)
    } catch (error) {
      console.error("Error exporting report data", error)
    }
  }

  const handleSaveReport = async (reportName, projectName, permission) => {
    if (!reportName || !projectName) return

    setLoading(true)

    const currentUrl = new URL(window.location.href)
    const reportPath = currentUrl.pathname + currentUrl.search

    const reportSaveUrl = `${baseUrl}/api/reports/save-report`

    let postBody = {
      user_id: userCtx.id,
      user_role: String(userCtx.user_role),
      values: props.selectedValues,
      url: reportPath,
      visualization: props.selectedValues.visualization,
      organization_alias: userCtx.organization_alias,
      report_name: reportName,
      report_id: reportId,
      project_name: projectName,
      permission,
    }

    if (reportId) {
      postBody.report_id = reportId
    }

    try {
      const response = await axios.post(reportSaveUrl, postBody)
      setReportId(response.data.doc_id)
      setLoading(false)
      setVisible(false)
    } catch (error) {
      setLoading(false)
      setVisible(false)
      console.error("Error saving report", error)
    }
  }

  const items = [
    {
      label: "Export Data",
      icon: "pi pi-download",
      command: () => handleExportData(),
    },
  ]

  return (
    <>
      <SplitButton
        label="Save Report"
        icon="pi pi-save"
        onClick={() => {
          const dataLayerBody = {
            step: 1,
          }
          triggerDataLayer("report_save", dataLayerBody)
          setVisible(true)
        }}
        model={items}
      />
      <Dialog
        header="Save Report"
        visible={visible}
        style={{ width: "30vw" }}
        onHide={() => setVisible(false)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 30,
            marginBottom: 45,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label for="">Report Name:</label>
            <InputText
              placeholder="Report Name"
              value={reportName}
              onChange={(e) => setReportName(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label for="">Project Name:</label>
            <InputText
              placeholder="Project Name"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <label for="">Permission:</label>
            {handlePermissionChoice()}
          </div>
        </div>

        {loading && (
          <div className="mb-3">
            <LoaderProgressBar />
          </div>
        )}

        <Button
          label="Save"
          className={props.isValidate ? "btn-secondary" : "btn-secondary grey"}
          disabled={!props.isValidate || loading}
          onClick={() => {
            const dataLayerBody = {
              step: 2,
              report_name: reportName,
              project_name: projectName,
            }
            triggerDataLayer("report_save", dataLayerBody)
            handleSaveReport(reportName, projectName, permission)
          }}
        />
      </Dialog>
    </>
  )
}

SaveReport.propTypes = {
  selectedValues: PropTypes.array.isRequired,
  isValidate: PropTypes.bool.isRequired,
}
