import React from "react"

export default function UserHelpText() {
  return (
    <div className="p-3" style={{ width: "80%" }}>
      <h3 className="flex gap-2">
        <i className="pi pi-exclamation-circle" style={{ fontSize: "2rem" }} />
        Plot visualization is loading...
      </h3>
      <p>
      Update filters and select <strong>Visualize</strong> to load a new chart and data table view.
      </p>
    </div>
  )
}
