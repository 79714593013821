import { useState, useEffect } from "react"
import { db } from "../firebase"
import _ from "lodash"

export default function useSubscribeAllDocs(args) {
  const { collection, withRef = {}, filter = [], limit = null } = args

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)

  useEffect(() => {
    let query = db.collection(collection)

    if (!_.isEmpty(filter)) {
      query = query.where(filter[0], filter[1], filter[2])
    }

    if (limit) {
      query = query.limit(limit)
    }

    const unsubscribe = query
      .orderBy("timestamp_updated", "desc")
      .onSnapshot(async (querySnapshot) => {
        let docList = []
        if (_.isEmpty(withRef)) {
          querySnapshot.forEach((doc) => {
            docList.push({
              id: doc.id,
              ...doc.data(),
            })
          })
        } else {
          for (const doc of querySnapshot.docs) {
            const docData = doc.data()
            const referenceField = docData[withRef.key]
            let referenceData = []
            try {
              const referenceDoc = await referenceField.get()
              referenceData = referenceDoc.data()
              const handleRefValues = () => {
                return _.reduce(
                  withRef.value,
                  (acc, value) => {
                    return {
                      ...acc,
                      [value]: referenceData[value],
                    }
                  },
                  {}
                )
              }
              docList.push({
                id: doc.id,
                ...doc.data(),
                [withRef.key]: handleRefValues(),
              })
            } catch (error) {
              docList.push({
                id: doc.id,
                ...doc.data(),
                [withRef.key]: referenceData,
              })
            }
          }
        }
        setData(docList)
        setLoading(false)
      })

    return () => {
      unsubscribe()
    }
  }, [])

  return [loading, error, data]
}
