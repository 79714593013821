import React, { useState, useEffect } from "react"
import Highcharts from "highcharts/highmaps"

import { SelectButton } from "primereact/selectbutton"

import topo from "../data/topo.json"
import topoCBSA from "../data/topo_cbsa.json"

const FILTER_OPTIONS = [
  { label: "Mean", value: "mean" },
  { label: "Min", value: "min" },
  { label: "Max", value: "max" },
]

const MapChart = (props) => {
  const [metricOption, setMetricOption] = useState("mean")

  function HandleMap({ mapData }) {
    const mapOption = "US_map"

    useEffect(() => {
      const processData = () => {
        const filteredMapData = {
          ...mapData,
          objects: {
            ...mapData.objects,
            default: {
              ...mapData.objects.default,
              geometries: mapData.objects.default.geometries.filter(
                (g) =>
                  g.properties["hc-key"] &&
                  g.properties["hc-key"].includes("-") &&
                  g.properties["hc-key"].split("-")[1].toUpperCase() ===
                    props.region
              ),
            },
          },
        }

        if (Array.isArray(filteredMapData.objects.default.geometries)) {
          filteredMapData.objects.default.geometries.forEach((g) => {
            const properties = g.properties
            if (properties["hc-key"]) {
              const stateAbbr = properties["hc-key"].substr(3, 2).toUpperCase()
              if (!properties.name.includes(stateAbbr)) {
                properties.name += `, ${stateAbbr}`
              }
            }
          })
        }

        const filteredData = props.data.filter((data) => {
          const searchKey =
            props.geo_boundary === "cbsa_msa_name" ? "geo_name" : "geo_key"
          const upperCaseCode = data[searchKey].toUpperCase()
          const stateCodesToInclude = ["TX", "NC"]
          return stateCodesToInclude.some((key) => upperCaseCode.includes(key))
        })

        const dataSetKey =
          props.weight_key === "number_providers"
            ? props.weight_key
            : metricOption

        const values = filteredData.map((item) => item[dataSetKey])
        const minValue = Math.min(...values)
        const maxValue = Math.max(...values)

        const metricNames = {
          mean: "Mean",
          min: "Min",
          max: "Max",
        }

        const handleTooltip = () => {
          if (props.isProvider) {
            return "<br /><b>{point.name}</b><br/>• Number of Providers: {point.number_providers}<br/>• Facility Provider Systems: {point.facility_system_primaries}<br />• Provider Types: {point.facility_provider_types}<br /><br /><b>Carrier Plans Rankings:</b>{point.rank_values}"
          }
          if (props.isNegocitated) {
            return "<br /><b>{point.name}</b><br/>• Mean: {point.mean}<br/>• Min: {point.min}<br/>• Max: {point.max}"
          }
        }

        Highcharts.mapChart("container", {
          chart: {
            map: filteredMapData,
            height: "30%",
          },
          title: {
            text: props.title,
          },
          subtitle: {
            text: props.subTitle,
          },
          accessibility: {
            description: "Demo showing a large dataset.",
          },
          legend: {
            layout: "vertical",
            align: "right",
            margin: 0,
            backgroundColor:
              (Highcharts.defaultOptions &&
                Highcharts.defaultOptions.legend &&
                Highcharts.defaultOptions.legend.backgroundColor) ||
              "rgba(255, 255, 255, 0.85)",
          },
          mapNavigation: {
            enabled: true,
          },
          colorAxis: {
            min: minValue,
            max: maxValue,
            tickInterval: Math.ceil((maxValue - minValue) / 5),
            stops: [
              [0, "#FFFFB2"],
              [1, "#BD0026"],
            ],
            labels: {
              format: "{value}",
            },
          },
          plotOptions: {
            mapline: {
              showInLegend: false,
              enableMouseTracking: false,
            },
          },
          series: [
            {
              data: filteredData.map((item) => ({
                ...item,
                value: item[dataSetKey],
              })),
              joinBy:
                props.geo_boundary === "cbsa_msa_name"
                  ? ["GEOID", "geo_key"]
                  : ["hc-key", "geo_key"],
              name: props.title,
              tooltip: {
                pointFormat: handleTooltip(),
              },
              borderWidth: 0.5,
              shadow: false,
              accessibility: {
                enabled: false,
              },
            },
            {
              type: "mapline",
              name: "State borders",
              color: "white",
              shadow: false,
              borderWidth: 2,
              accessibility: {
                enabled: false,
              },
            },
          ],
        })
      }
      processData()
    }, [mapData, mapOption])

    return <div id="container" className="highcharts-light" />
  }

  return (
    <div>
      {props.weight_key === "mean" && (
        <SelectButton
          value={metricOption}
          options={FILTER_OPTIONS}
          onChange={({ value }) => setMetricOption(value)}
          multiple={false}
          style={{ marginBottom: 30 }}
        />
      )}
      <HandleMap
        mapData={props.geo_boundary === "cbsa_msa_name" ? topoCBSA : topo}
      />
    </div>
  )
}

export default MapChart
