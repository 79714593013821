import React, { useEffect } from "react"

import Dashboard from "../components/Dashboard"
import { LoaderSmall } from "../../../../components/Loaders"

// hook
import useGetHCData from "../hooks/useGetHCData"

export default function CarrierNetworkAnalytics() {
  const endpoint = "carrier-network-analytics"
  const [loading, error, data, fetchData] = useGetHCData()

  useEffect(() => {
    fetchData(endpoint)
  }, [])

  if (error) {
    return (
      <div>
        <h2>Error when fetching.</h2>
      </div>
    )
  }

  if (loading) return <LoaderSmall />
  if (!data) return null

  return <Dashboard config={data} />
}
