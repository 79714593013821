import React, { useEffect, useState, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import _ from "lodash"
import { DateTime } from "luxon"
import axios from "axios"

import { Button } from "primereact/button"
import { SplitButton } from "primereact/splitbutton"

import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Toast } from "primereact/toast"

import { GET, DELETE } from "../../config/constants"
import cloudFunctionsURL from "../../config/cloudFunctionsURL"

import useSubscribeAllDocs from "../../hooks/useSubscribeAllDocs"
import handleStatusMessage from "../../helpers/handleStatusMessage"

export default function ProjectsAndDataOrders() {
  const [deletingDataOrder, setDeletingDataOrder] = useState(false)

  const [fetching, setFetching] = useState(true)
  const [projectMetadata, setProjectMetadata] = useState([])

  const hookParams = {
    collection: "mrf_orders",
    filter: ["mrf_type", "==", "in_network"],
  }

  const [loading, error, data] = useSubscribeAllDocs(hookParams)

  const navigate = useNavigate()
  const toast = useRef(null)

  useEffect(() => {
    if (!data) return
    const getProjectMetadata = async () => {
      const projectMetadataList = await Promise.all(
        _.map(data, async ({ id: mrf_order_id }) => {
          const metadataUrl = `${cloudFunctionsURL()}/${
            GET.PROJECT_METADATA_FROM_MRF_ORDER
          }`
          try {
            const res = await axios.post(metadataUrl, {
              mrf_order_id,
            })
            const fetchData = res.data.data
            fetchData.mrf_order_id = mrf_order_id
            return fetchData
          } catch (error) {
            return null
          }
        })
      )
      setProjectMetadata(projectMetadataList)
      setFetching(false)
    }
    getProjectMetadata()
  }, [data])

  const handleData = _.map(data, (row) => {
    const currentProjectMetadata =
      _.find(projectMetadata, {
        mrf_order_id: row.id,
      }) || {}

    const handleDelete = async (docId) => {
      try {
        setDeletingDataOrder(true)
        const url = `${cloudFunctionsURL()}/${DELETE.DATA_ORDER}`
        const response = await axios.post(url, {
          data_order_id: docId,
        })
        setDeletingDataOrder(false)
        if (response.data.status === "success") {
          return toast.current.show({
            severity: "success",
            summary: "Data Order Deleted",
            detail: response.data.message,
            life: 3000,
          })
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error while trying to delete Data Order",
            detail: "The document was not successfully deleted.",
            life: 3000,
          })
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Error Deleting Document",
          detail: "An error occurred while deleting the document.",
          life: 3000,
        })
      }
    }

    const handleActions = () => {
      const {
        data_order_id: docId,
        data_order_status: status,
        project_alias,
      } = currentProjectMetadata

      const items = [
        {
          label: "View Project",
          icon: "pi pi-eye",
          command: () => navigate(`/project/details/${project_alias}`),
        },
        {
          label: "Update Project",
          icon: "pi pi-pencil",
          command: () => navigate(`/project/edit/${project_alias}`),
        },
        {
          label: "Find MRF to process",
          icon: "pi pi-search",
          command: () => navigate(`/data-order/edit/${docId}`),
        },
        {
          label: "Add MRF to process",
          icon: "pi pi-plus",
          command: () => navigate(`/data-order/edit/${docId}?screen=3`),
        },
        {
          label: "Delete",
          icon: "pi pi-times",
          command: () => handleDelete(docId),
        },
      ]
      return (
        <SplitButton
          label="View Order"
          onClick={() => navigate(`/data-order/details/${docId}/${status}`)}
          model={items}
          disabled={deletingDataOrder}
        />
      )
    }

    const handleMrfOrderName = (name) => {
      if (!name) return
      const maxLength = 24
      if (name.length > maxLength) {
        return name.substring(0, maxLength) + "..."
      }
      return name
    }

    const modDate = row.timestamp_updated
      ? row.timestamp_updated.toDate()
      : null

    return {
      ...row,
      mrf_order_name: handleMrfOrderName(row.mrf_order_name),
      mrf_order_stage: handleStatusMessage(row.mrf_order_stage),
      mrf_order_status: handleStatusMessage(row.mrf_order_status),
      project_name: currentProjectMetadata.project_name,
      updated: DateTime.fromJSDate(modDate).toFormat("F"),
      actions: handleActions(),
    }
  })

  return (
    <div>
      <h1>Project Data Orders</h1>

      <div style={{ display: "flex", gap: 5, marginBottom: 15 }}>
        <Button
          onClick={() => navigate("/data-order/add")}
          className="btn-secondary"
        >
          Create New Data Order
        </Button>
        <Button onClick={() => navigate("/project/add")}>
          Create New Project
        </Button>
      </div>

      <DataTable
        value={handleData}
        responsiveLayout="scroll"
        loading={loading || fetching}
      >
        <Column field="project_name" header="Project Name" filter sortable />
        <Column
          field="mrf_order_name"
          header="Data Order Name"
          filter
          sortable
        />
        <Column field="mrf_order_stage" header="Stage" />
        <Column field="mrf_order_status" header="Status" />
        <Column field="updated" header="Last Order Date" sortable />
        <Column field="actions" header="Actions" style={{ width: 240 }} />
      </DataTable>
      <Toast ref={toast} />
    </div>
  )
}
