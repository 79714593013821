import { useState, useEffect, useContext } from "react"
import { UserContext } from "../context/userContext"

import axios from "axios"

// URL
import cloudFunctionsURL from "../config/cloudFunctionsURL"

export default function useGetFormDef(args) {
  const userCtx = useContext(UserContext)

  const { formId, endpoint } = args
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  useEffect(() => {
    const fetch = async () => {
      const url = cloudFunctionsURL()
      const fullURL = `${url}/${endpoint}`
      try {
        const res = await axios.post(fullURL, {
          formId,
          user_role: userCtx.user_role,
        })
        const parsedData = JSON.parse(res.data.data)
        setData(parsedData)
        setLoading(false)
      } catch (err) {
        setError(err)
        setLoading(false)
      }
    }
    fetch()
  }, [])

  return [error, loading, data]
}
