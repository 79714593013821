import React, { useState, useEffect } from "react"
import { DateTime } from "luxon"

import _ from "lodash"
import { useParams } from "react-router-dom"

import axios from "axios"

import cloudFunctionsURL from "../../config/cloudFunctionsURL"
import { GET } from "../../config/constants"

import { Card } from "primereact/card"
import { Button } from "primereact/button"

// Data Grid
import ReactDataGrid from "@inovua/reactdatagrid-enterprise"

// Loaders
import { LoaderProgressBar } from "../../components/Loaders"

// Filters
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter"
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter"

import handleStatusMessage from "../../helpers/handleStatusMessage"

import "@inovua/reactdatagrid-enterprise/index.css"

export default function DataOrderDetailsPreProcess() {
  const { docId, status } = useParams()

  const [loading, serLoading] = useState(false)

  const [data, setData] = useState([])
  const [dataOrderResults, setDataOrderResults] = useState({})

  const [columns, setColumns] = useState([])
  const [defaultFilterValue, setDefaultFilterValue] = useState([])

  useEffect(() => {
    const URL = `${cloudFunctionsURL()}/${GET.GET_PRE_PROCESS_SUMMARY}`
    const fetch = async () => {
      serLoading(true)
      try {
        const response = await axios.post(URL, {
          data_order_id: docId,
          status,
        })
        const handleData = _.map(response.data.data, (row) => {
          return {
            ...row,
            timestamp_updated: DateTime.fromSeconds(
              row.timestamp_updated._seconds
            ).toFormat("F"),
            markers_status: handleStatusMessage(row.markers_status),
            markers_results_csv: (
              <Button
                onClick={() => window.open(row.markers_results_csv, "_blank")}
                label="Download"
                icon="pi pi-cloud-download"
                size="small"
                className="p-button-sm"
              />
            ),
          }
        })
        setData(handleData)
        const handleColumns = _.map(response.data.columns, (column) => {
          if (!_.isEmpty(column.filterEditor)) {
            switch (column.filterEditor) {
              case "SelectFilter":
                column.filterEditor = SelectFilter
                return column
              case "NumberFilter":
                column.filterEditor = NumberFilter
                return column
              case "DateFilter":
                column.filterEditor = "" // not in use.
                return column
              default:
                return column
            }
          }
          return column
        })
        setColumns(handleColumns)
        setDefaultFilterValue(response.data.defaultFilterValue)
        setDataOrderResults(response.data.data_order_results)
        serLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    fetch()
  }, [])

  const handleDataOrderResults = () => {
    if (_.isEmpty(dataOrderResults)) return null
    return (
      <Card
        title={`${dataOrderResults.project_name}, ${dataOrderResults.data_order_name}`}
      >
        <div style={{ marginBottom: 15 }}>
          <Button
            onClick={() =>
              window.open(dataOrderResults.preprocess_summary_csv, "_blank")
            }
            label="Download Summary"
            icon="pi pi-cloud-download"
            size="small"
            className="p-button-sm"
          />
        </div>

        <div style={{ marginBottom: 10 }}>
          <a href={dataOrderResults.result_markers_csv} target="_blank">
            <i className="pi pi-external-link" style={{ marginRight: 5 }} />
            Go to Results Folder
          </a>
        </div>

        {dataOrderResults.markers_storage_csv && (
          <div style={{ marginBottom: 30 }}>
            <a href={dataOrderResults.markers_storage_csv} target="_blank">
              <i className="pi pi-download" style={{ marginRight: 5 }} />
              Download Markers CSV
            </a>
          </div>
        )}

        <div>
          Updated:{" "}
          {DateTime.fromSeconds(
            dataOrderResults.datetime_updated._seconds
          ).toFormat("ff")}
        </div>

        <div style={{ marginTop: 10 }}>
          {handleStatusMessage(dataOrderResults.data_order_status)}
        </div>
      </Card>
    )
  }

  if (loading) return <LoaderProgressBar />

  const renderRowDetails = ({ data }) => {
    return (
      <div style={{ padding: 20 }}>
        <h3>Row details:</h3>
        <table>
          <tbody>
            {Object.keys(data).map((name, i) => {
              return (
                <tr key={i}>
                  <td>{name}</td>
                  <td>{data[name]}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div>
      <h1>Pre Process Results</h1>
      {handleDataOrderResults()}
      <ReactDataGrid
        licenseKey={process.env.REACT_APP_RDG}
        idProperty="id"
        pagination
        defaultFilterValue={defaultFilterValue}
        columns={columns}
        style={{ minHeight: "60vh", marginTop: 15 }}
        dataSource={data}
        enableColumnAutosize
        rowExpandHeight={300}
        rowHeight={60}
        renderRowDetails={renderRowDetails}
      />
    </div>
  )
}
