import React, { useState, useEffect, useContext } from "react"
import _ from "lodash"
import axios from "axios"

// Ctx
import { UserContext } from "../../../../context/userContext"

// PR
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

// Comps
import FilterBuilder from "../../components/FieldBuilder"
import UserHelpText from "../../components/UserHelpText"

// DataRoom URL
import { dataRoomURL } from "../../../../config/cloudFunctionsURL"

// Loaders
import LoaderFullPage, {
  LoaderProgressBar,
} from "../../../../components/Loaders"

// Styles
import styles from "../../../../styles/DataRoom.module.css"

// HC
import Bar from "./highcharts/Bar"
import GroupedBar from "./highcharts/GroupedBar"
import Density from "./highcharts/Density"

const baseUrl = dataRoomURL()

export default function CommercialInsuranceNegotiatedRates() {
  const [loading, setLoading] = useState(true)
  const [fetching, setFetching] = useState(false)

  const userCtx = useContext(UserContext)

  const [filterList, setFilterList] = useState([])

  const [payload, setPayload] = useState({})
  const [geomeandata, setGeomeandata] = useState([]) // TODO: check if this is needed

  // Deprecated
  const [maxOpBillingCodeTypeLabel, setMaxOpBillingCodeTypeLabel] = useState(8) // TODO: check if this is needed

  // Reports: *******TODO: Remove*******
  const [reportId, setReportId] = useState("")
  const [projectName, setProjectName] = useState("")
  const [reportName, setReportName] = useState("")

  // Params from URL
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  // URL Param Values + Visualization
  const carriedFieldValues = {
    region: urlParams.get("region") ?? "",
    region_value: urlParams.get("region_value") ?? "",
    segment: urlParams.get("segment") ?? "",
    billing_code_category: urlParams.get("billing_code_category") ?? "",
    billing_code_category_value:
      urlParams.get("billing_code_category_value") ?? "",
    reportId: urlParams.get("report_id") ?? null,
    visualization: urlParams.get("visualization") ?? "bar",
  }

  // NEW: First call to def
  useEffect(() => {
    setLoading(true)
    async function fetchData() {
      try {
        const response = await axios.post(
          `${baseUrl}/api/filters/definitions/analytic-dashboard/2`,
          carriedFieldValues
        )
        const filterList = response.data?.filter_list || []
        setFilterList(filterList)
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  // TODO: Check if this is needed
  const getStoredValues = async () => {
    const { report_id } = carriedFieldValues
    setReportId(report_id)
    const url = `${baseUrl}/api/reports/get-full-report`
    const response = await axios.post(url, {
      report_id,
    })
    const reportName = response.data.report_name || ""
    const projectName = response.data.project_name || ""
    setProjectName(projectName)
    setReportName(reportName)
    const values = response.data.values || {}
    return values
  }

  const handleGenerate = async (selectedValues = {}) => {
    setPayload({})
    setFetching(true)
    const postBody = {
      ...selectedValues,
      user_role: userCtx.user_role.toString(), // temp until JWT
      data_region: userCtx.data_region.toString(), // temp until JWT
    }
    try {
      const fetchUrl = `${baseUrl}/api/dashboard/commercial-insurance-negotiated-rates`
      const response = await axios.post(fetchUrl, postBody)
      setPayload(response.data)
      setFetching(false)
    } catch (error) {
      console.log("Error fetching data", error)
      setFetching(false)
    }
  }

  const handleGeomeanTable = () => {
    if (_.isEmpty(geomeandata)) return null

    const groupedData = _.chain(geomeandata)
      .groupBy("billing_code_type_label")
      .map((codeData, code) => ({
        billingCodeTypeLabel: code,
        ..._.mapValues(
          _.keyBy(codeData, "carrier_name"),
          "geomean_negotiated_rate"
        ),
      }))
      .value()

    // Flattening the array of objects into an array of rows
    const dataTableArray = _.flatMap(groupedData, (row) => [row])

    const columns = Object.keys(dataTableArray[0]).map((column) => ({
      field: column,
      header: column,
    }))

    return (
      <DataTable value={dataTableArray}>
        {columns.map((col) => (
          <Column key={col.field} field={col.field} header={col.header} />
        ))}
      </DataTable>
    )
  }

  const handleChartPerType = () => {
    if (fetching) return <LoaderProgressBar />
    if (_.isEmpty(payload)) return <UserHelpText />
    switch (carriedFieldValues.visualization) {
      case "bar":
        return <Bar payload={payload} />
      case "grouped-bar":
        return <GroupedBar payload={payload} />
      case "density":
        return <Density payload={payload} />
      default:
        return null
    }
  }

  // temp for 'ths_sandbox' organization
  const userOrg = userCtx?.organization_alias
  const handleHideText = () => {
    if (userOrg === "ths_sandbox") {
      return "hidden"
    }
    return ""
  }

  function HandleHeader() {
    if (carriedFieldValues.visualization === "bar") {
      return (
        <div>
          <h1>
            Analytic Dashboard: Market Rate Competitiveness in Clinical
            Categories
          </h1>
          <p>
            The dashboard displays a normalized average standard deviation of
            the mean negotiated rates for all billing codes in a selected
            clinical categories and episodes. To arrive at this categorical
            deviation, mean billing code rates for Commercial Insurance
            Carriers, Facility Provider Systems, or Facility Provider Types are
            standardized to a z-score to support comparative data.
          </p>
          <p>
            In general, the chart data aims to assist in market steerage
            decisions. The data can be interpreted as: On average, the selected
            carrier, facility system, or provider type reports negotiated
            billing code rates within a selected clinical category that are
            below (more competitive) or above (less competitive) the expected
            market rate averages. This expected market average is indicated by
            the 0-value line (no standard deviations) on the chart.
          </p>
          <p>
            Loaded in this tool for modeling is a basket of top billing codes
            representing steady state utilization in a commercial population
            derived from{" "}
            <span className={handleHideText()}>MMA Region claims, </span>
            MarketScan Commercial Claims and Encounters Database and the
            Healthcare Cost and Utilization Project.
          </p>
        </div>
      )
    } else if (carriedFieldValues.visualization === "grouped-bar") {
      return (
        <div>
          <h1>
            Analytic Dashboard: Descriptive Billing Code Negotiated Rates within
            Clinical Categories
          </h1>
          <p>
            The dashboard provides standard descriptive statistics for
            negotiated rates from regional commercial insurance carriers. Rates
            are specific to medical and procedural billing codes within selected
            clinical categories. Loaded in this tool for modeling is a basket of
            top billing codes representing steady state utilization in a
            commercial population derived from
            <span className={handleHideText()}> MMA Region claims,</span>{" "}
            MarketScan Commercial Claims and Encounters Database and the
            Healthcare Cost and Utilization Project. These descriptive values
            include the minimum negotiated rate for regional providers, the mean
            (average) billing code rate, the maximum billing code rate, and the
            number of distinct rates reported by respective carriers for each
            categorical billing code.
          </p>
          <p>
            Select the descriptive type displayed on the chart for grouped rate
            values by commercial insurance carrier, facility provider system, or
            facility provider type.
          </p>
        </div>
      )
    }
  }

  return (
    <div className={`${styles.pageLayout} data-room`}>
      <HandleHeader />

      <div className={styles.wrapper}>
        <div className="filters">
          <FilterBuilder
            filterGroupId="analytic-dashboard"
            screen={2}
            carriedFieldValues={carriedFieldValues}
            handleGenerate={handleGenerate}
          />
        </div>
        <div className={styles.plot}>
          {handleChartPerType()}
          {handleGeomeanTable()}
        </div>
      </div>
    </div>
  )
}
