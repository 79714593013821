import React from "react"
import { Link } from "react-router-dom"
import styles from "../../styles/NavMain.module.css"

export default function MenuAnalyst() {
  return (
    <div className={styles.mainMenu}>
      <ul>
        <li>
          <Link to="/">Workbench</Link>
        </li>
        <li>
          <Link to="/data-room/">Data Room</Link>
        </li>
      </ul>
    </div>
  )
}
