import _ from "lodash"
import * as Yup from "yup"

export default function schemaBuilder(fields) {
  const initialValues = _.reduce(
    fields,
    (acc, field) => {
      const skipFieldTypes = ["control"]

      if (_.includes(skipFieldTypes, field.type)) return acc

      const defaultOption = field.defaultOption || null

      if (field.type === "multi-selector") {
        return {
          ...acc,
          [field.fieldname]: defaultOption || [],
        }
      }

      return {
        ...acc,
        [field.fieldname]: defaultOption || "",
      }
    },
    {}
  )

  const handleValidation = _.reduce(
    fields,
    (acc, field) => {
      const skipTypes = ["control"]

      if (_.includes(skipTypes, field.type)) return acc

      const [isRequiered, msg] = field.validation

      if (!isRequiered) return acc

      const isConditional = !_.isEmpty(field.field_conditional)

      if (isConditional) {
        const conditionalFieldKey = Object.keys(field.field_conditional)[0]
        const conditionalFieldValue = Object.values(field.field_conditional)[0]
        switch (field.type) {
          case "url":
            return {
              ...acc,
              [field.fieldname]: Yup.string().when(conditionalFieldKey, {
                is: (value) => conditionalFieldValue.includes(value),
                then: () =>
                  Yup.string()
                    .url("Invalid URL format.")
                    .required(msg),
              }),
            }
          case "gs":
            return {
              ...acc,
              [field.fieldname]: Yup.string().when("mrf_source_manual_input", {
                is: (value) => conditionalFieldValue.includes(value),
                then: () =>
                  Yup.string()
                    .matches(
                      /^gs:\/\/.+/,
                      "Cloud Storage location must start with 'gs://..'"
                    )
                    .required(msg),
              }),
            }
          default:
            return acc
        }
      }

      if (field.fieldname === "mrf_date") {
        return {
          ...acc,
          [field.fieldname]: Yup.string()
            .matches(
              /^\d{4}-\d{2}-\d{2}$/,
              "Invalid date format. Please use YYYY-MM-DD."
            )
            .required(msg),
        }
      }

      if (field.fieldname === "product_coverage_area_states") {
        return {
          ...acc,
          [field.fieldname]: Yup.array().of(Yup.string().required(msg)),
        }
      }

      switch (field.type) {
        case "url":
          return {
            ...acc,
            [field.fieldname]: Yup.string()
              .url("Invalid URL format.")
              .required(msg),
          }
        case "gs":
          return {
            ...acc,
            [field.fieldname]: Yup.string()
              .matches(
                /^gs:\/\/.+/,
                "Cloud Storage location must start with 'gs://..'"
              )
              .required(msg),
          }
        case "number":
          return {
            ...acc,
            [field.fieldname]: Yup.number().required(msg),
          }
        case "email":
          return {
            ...acc,
            [field.fieldname]: Yup.string()
              .email("Invalid E-mail format.")
              .required(msg),
          }
        case "multi-selector":
          return {
            ...acc,
            [field.fieldname]: Yup.array().required(msg),
          }
        default:
          return {
            ...acc,
            [field.fieldname]: Yup.string().required(msg),
          }
      }
    },
    {}
  )
  const handleValidationSchema = () => Yup.object().shape(handleValidation)
  return [initialValues, handleValidationSchema()]
}
